import { LOGIN_BEGINS, LOGIN_SUCCESS, LOGIN_FAILURE } from '../../helpers/constants'
import { API_URL } from '../../helpers/config'
import axios from 'axios';
import { history } from '../../helpers/history'


export const doLogin = (data) => dispatch => {
    dispatch({ type: LOGIN_BEGINS });
    let formdata = new FormData();
    formdata.append('email', data.username);
    formdata.append('password', data.password);

    let toPost = {
        'email': data.username,
        'password': data.password

    }

    axios.post(API_URL + 'auth/login', toPost)
        .then(res => {
            if (res.data.status === 'success') {
                localStorage.setItem('myToken', res.data.token);
                localStorage.setItem('myStatus', "logged_in");
                localStorage.setItem('user', res.data.user &&res.data.user.name  );
                localStorage.setItem('role', res.data.user &&res.data.user.role  );
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data.token
                })
                 window.location.href = '/'; 
                // setTimeout(() => {
                //     history.push('/')
                // });

            } else {
                localStorage.setItem('myStatus', 'logged_out');
                dispatch({
                    type: LOGIN_FAILURE,
                    payload: res.data.message
                })
            }
        })
        .catch(error => dispatch({
            type: LOGIN_FAILURE,
            payload: 'API failed'
        }));
}