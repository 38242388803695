
import {CHANGE_TRASH_IDS, CHANGE_TRASH_STATUS } from '../helpers/constants'


export default (state = {ids:[], status:false}, action) => {
    switch (action.type) {
        case CHANGE_TRASH_IDS:
            return {
                ...state,
                ids:action.payload
             
              
            }
        case CHANGE_TRASH_STATUS:
            return {
                ...state,
                status:action.payload
                
              
            }
        default:
            return state
    }
}