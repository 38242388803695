
import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, Card, Typography, Avatar } from 'antd';
import {
  UserOutlined,
  WalletOutlined,
  CreditCardOutlined,
  CarryOutOutlined,
  TeamOutlined,
  EllipsisOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { getClientDetails } from '../actions/clientAction'
const { Title, Paragraph, Text } = Typography;

const CamalCase = (text) => {
  if (text) {
    let t1 = ""
    let t2 = ""
    let splitTxt = text.split(" ")
    if (splitTxt[0]) {
      t1 = splitTxt[0].charAt(0).toUpperCase() +
        splitTxt[0].slice(1);
    }
    if (splitTxt[1]) {
      t2 = splitTxt[1].charAt(0).toUpperCase() +
        splitTxt[1].slice(1);
    }
    return t1 + " " + t2

  } else return "";

}
const makeLogo = (text) => {
  if (text) {
    let t1 = ""
    let t2 = ""
    let splitTxt = text.split(" ")
    if (splitTxt[0]) {
      t1 = splitTxt[0].charAt(0).toUpperCase()
    }
    if (splitTxt[1]) {
      t2 = splitTxt[1].charAt(0).toUpperCase()

    }
    return t1 + t2

  } else return "";

}
class ClientProfileContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      list: 0
    }





  }



  componentDidMount() {

    let tkn = localStorage.getItem('myToken')
    if (tkn) {
      // let decoded  =  jwt_decode(tkn)
    }
    let id = this.props.match.params.id

    this.props.getClientDetails(id)



  }


  render() {
    const { clientDetails, profileLoader } = this.props



    return (

      <div className="clients">

        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>

            <div className="filterArea">

              <Card bordered={false} style={{ "height": "100%", "marginTop": "16px" }} >
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Breadcrumb >
                      <Breadcrumb.Item>Admin</Breadcrumb.Item>
                      <Breadcrumb.Item><Link to="/clients">Clients</Link></Breadcrumb.Item>
                      <Breadcrumb.Item>Profile</Breadcrumb.Item>
                    </Breadcrumb>




                  </Col>
                </Row>

              </Card>



            </div>


          </Col>
        </Row>


        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 24]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>

            <Card bordered={false} loading={profileLoader} style={{ "height": "100%" }} >

              {clientDetails && <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 24]}>
             
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <h3 className="client-type" ><TeamOutlined />Client Type : Fully MANAGED</h3>

                  </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>

    <h3>Client Name and Address	: </h3>
                <p className="infoBlock">
                {CamalCase(clientDetails.Name)},<br/>
                 {clientDetails.Address},<br/>
                 {clientDetails.Email},<br/>
                 {clientDetails.Phone},<br/>

                </p>

                <h3>Data Center : </h3>
                <p className="infoBlock" dangerouslySetInnerHTML={{__html:clientDetails.data_helpdeskurl}}  >
                {/* {clientDetails.data_helpdeskurl}<br/>
                {clientDetails.data_Username}<br/>
                {clientDetails.data_Password}<br/> */}

               
                </p>

                <h3>Nameservers : </h3>
                <p className="infoBlock"  dangerouslySetInnerHTML={{__html:clientDetails.nameservers}} >
                
                {/* {clientDetails.nameservers}<br/> */}

                </p>

                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                 

                  <h3>Helpdesk : </h3>
                <p className="infoBlock" dangerouslySetInnerHTML={{__html:clientDetails.HelpdeskUrl}}  >
                {/* {clientDetails.HelpdeskUrl}<br/>
                {clientDetails.Username}<br/>
                {clientDetails.password}<br/> */}

                 
                </p>

                  <h3>Escalation Procedure : </h3>
                <p className="infoBlock" dangerouslySetInnerHTML={{__html:clientDetails.escalation_procedure}}  >
                {/* {clientDetails.HelpdeskUrl}<br/>
                {clientDetails.Username}<br/>
                {clientDetails.password}<br/> */}

                 
                </p>


                  <h3>Monitoring  System : </h3>
                <p className="infoBlock" dangerouslySetInnerHTML={{__html:clientDetails.monitoring_status}}  >
                {/* {clientDetails.monitoring_status}<br/> */}

                </p>

                  <h3>Backup Info : </h3>
                <p className="infoBlock"  dangerouslySetInnerHTML={{__html:clientDetails.BackupPolicy}}>
                {/* {clientDetails.BackupPolicy}<br/> */}

                </p>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                 

                  <h3>Other logins (ENOM Virtualizor etc) : </h3>
                <p className="infoBlock"  dangerouslySetInnerHTML={{__html:clientDetails.other_details}}>
                

                </p>


                  <h3>Server List : </h3>
                <p className="infoBlock"  dangerouslySetInnerHTML={{__html:clientDetails.servers_list}}>
                  {/* <ul>
    {clientDetails.servers_list && clientDetails.servers_list.split(",").map((item)=>(<li>{item}</li>))}
                </ul> */}
                </p>

                  <h3>Client Instructions : </h3>
                <p className="infoBlock"  dangerouslySetInnerHTML={{__html:clientDetails.specialrequests}} >
                {/* {clientDetails.specialrequests}<br/> */}
                </p>
                  <h3>Terms and Conditions : </h3>
                <p className="infoBlock"  dangerouslySetInnerHTML={{__html:clientDetails.termsandConditions}}>
                {/* {clientDetails.termsandConditions}<br/> */}

                </p>

                </Col>
              </Row>}



            </Card>
          </Col>


        </Row>

      </div>



    )





  }


}
const mapStateToProps = state => {
  return {
    clientDetails: state.clientDetailsReducer.result,
    profileLoader: state.clientDetailsReducer.loader,
    clientDetailsError: state.clientDetailsReducer.error,
  }
}
const mapDispatchToProps = dispatch => ({
  getClientDetails: (id) => dispatch(getClientDetails(id)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ClientProfileContainer)