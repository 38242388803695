export const LOGIN_BEGINS = 'LOGIN_BEGINS' 
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS' 
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

//dashboard

export const TICKET_FETCH_BEGINS = 'TICKET_FETCH_BEGINS'
export const TICKET_FETCH_SUCCESS = 'TICKET_FETCH_SUCCESS'
export const TICKET_FETCH_FAILURE = 'TICKET_FETCH_FAILURE'

//change status

export const STATUS_CHANGE_BEGINS = 'STATUS_CHANGE_BEGINS'
export const STATUS_CHANGE_SUCCESS = 'STATUS_CHANGE_SUCCESS'
export const STATUS_CHANGE_FAILURE = 'STATUS_CHANGE_FAILURE'

// CHANGE_LIST_STATUS
export const CHANGE_LIST_STATUS = 'CHANGE_LIST_STATUS'

// CHANGE_TRASH_IDS
export const CHANGE_TRASH_IDS = 'CHANGE_TRASH_IDS'
export const CHANGE_TRASH_STATUS = 'CHANGE_TRASH_STATUS'

// Close Tickets
export const CLOSE_TICKETS_BEGINS = 'CLOSE_TICKETS_BEGINS'
export const CLOSE_TICKETS_SUCCESS = 'CLOSE_TICKETS_SUCCESS'
export const CLOSE_TICKETS_FAILURE = 'CLOSE_TICKETS_FAILURE'

// TICKET_COMMENT

export const COMMENT_TICKET_BEGINS = 'COMMENT_TICKET_BEGINS'
export const COMMENT_TICKET_SUCCESS = 'COMMENT_TICKET_SUCCESS'
export const COMMENT_TICKET_FAILURE = 'COMMENT_TICKET_FAILURE'

// TICKET_REPLY

export const REPLY_TICKET_BEGINS = 'REPLY_TICKET_BEGINS'
export const REPLY_TICKET_SUCCESS = 'REPLY_TICKET_SUCCESS'
export const REPLY_TICKET_FAILURE = 'REPLY_TICKET_FAILURE'

// USER MANAGEMENT

export const USER_FETCH_BEGINS = 'USER_FETCH_BEGINS'
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS'
export const USER_FETCH_FAILURE = 'USER_FETCH_FAILURE'

export const CREATE_USER_BEGINS = 'CREATE_USER_BEGINS'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const UPDATE_USER_BEGINS = 'UPDATE_USER_BEGINS'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
// CLIENT MANAGEMENT

export const CLIENT_FETCH_BEGINS = 'CLIENT_FETCH_BEGINS'
export const CLIENT_FETCH_SUCCESS = 'CLIENT_FETCH_SUCCESS'
export const CLIENT_FETCH_FAILURE = 'CLIENT_FETCH_FAILURE'

export const CLIENT_DETAILS_FETCH_BEGINS = 'CLIENT_DETAILS_FETCH_BEGINS'
export const CLIENT_DETAILS_FETCH_SUCCESS = 'CLIENT_DETAILS_FETCH_SUCCESS'
export const CLIENT_DETAILS_FETCH_FAILURE = 'CLIENT_DETAILS_FETCH_FAILURE'

export const CREATE_CLIENT_BEGINS = 'CREATE_CLIENT_BEGINS'
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS'
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE'
export const CREATE_CLIENT_CLEAR = 'CREATE_CLIENT_CLEAR'

export const UPDATE_CLIENT_BEGINS = 'UPDATE_CLIENT_BEGINS'
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS'
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE'

export const CLIENT_DETAILS_EDIT_BEGINS = 'CLIENT_DETAILS_EDIT_BEGINS'
export const CLIENT_DETAILS_EDIT_SUCCESS = 'CLIENT_DETAILS_EDIT_SUCCESS'
export const CLIENT_DETAILS_EDIT_FAILURE = 'CLIENT_DETAILS_EDIT_FAILURE'
export const CLIENT_DETAILS_EDIT_CLEAR = 'CLIENT_DETAILS_EDIT_CLEAR'