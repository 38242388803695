import React, { useEffect, useState } from 'react';
import {  Progress } from 'antd';
import moment from 'moment'
function LiveProgress(props) { 
    
    const {postedDate, status, closedDate} = props
    // const postedDate = "2020-04-17 16:36:00"

    const twoN = (n)=>{
      return n > 9 ? "" + n: "0" + n;
  }
    //closed time laps

    let postedDateUtc = moment.utc(postedDate).toDate();
    let  postedDateLocal = moment(postedDateUtc).local().format('YYYY-MM-DD HH:mm:ss');

    let closedDateUtc = moment.utc(closedDate).toDate();
    let  closedDateLocal = moment(closedDateUtc).local().format('YYYY-MM-DD HH:mm:ss');
    
    const closedDifference = +new Date(closedDateLocal) - +new Date(postedDateLocal);
    let timeLaps = {};

    if (closedDifference > 0) {
      timeLaps = {
        //   days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: twoN(Math.floor((closedDifference / (1000 * 60 * 60)))),
          minutes: twoN(Math.floor((closedDifference / 1000 / 60) % 60)),
          seconds: twoN(Math.floor((closedDifference / 1000) % 60))
        };
      }

      console.log("time laps", timeLaps)

    // end of closed time laps


    const calculateTimeLeft =() =>{

       

       
        let stillUtc = moment.utc(postedDate).toDate();
        let  local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');

        const difference = +new Date() - +new Date(local);
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
            //   days: Math.floor(difference / (1000 * 60 * 60 * 24)),
              hours: Math.floor((difference / (1000 * 60 * 60))),
              minutes: twoN(Math.floor((difference / 1000 / 60) % 60)),
              seconds: twoN(Math.floor((difference / 1000) % 60))
            };
          }
      
          return timeLeft;
        };


        const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

        useEffect(() => {
            setTimeout(() => {
              setTimeLeft(calculateTimeLeft());
            }, 1000);
          });


          const timerComponents = [];

          Object.keys(timeLeft).forEach(interval => {
            if (!timeLeft[interval]) {
            //   return;
            timerComponents.push(
                <span style={{"paddingLeft":"5px"}}>
                  {"0"}
                </span>
              );
            }
        
            timerComponents.push(
              <span>
                {timeLeft[interval]}{interval!="seconds"?":":''}
              </span>
            );

           
          });

          const  progress = (timeLeft['hours']*60+timeLeft['minutes'])
          const perCent = Math.round(( progress/60)*100)
let strokeColor = "#009900"

if(perCent>=100){
    strokeColor = "#ff0000"

}else if(perCent>=95){
    strokeColor = "#ff6600"

}else if(perCent>=75){
    strokeColor = "#ff9933"

}else if(perCent>=50){
    strokeColor = "#ffcc00"

} else if(perCent>=25){
    strokeColor = "#2C76FF"

}



          return (
           
     <>

      {status!="closed"? <div className="prgs">
<Progress percent={perCent}  status={progress>60?"exception":"active"} showInfo={false} strokeColor={strokeColor} trailColor={"#e3e5e8"}   />
     {timerComponents.length ? timerComponents : ''}
   </div> :<div className="prgs" style={{"color":'red'}}>

   {(Object.keys(timeLaps).length === 0 && timeLaps.constructor === Object)? '':  `Closed in ${timeLaps['hours']}:${timeLaps['minutes']}:${timeLaps['seconds']} Hours`}
   </div>}
             
        </>  );
}

export default LiveProgress
