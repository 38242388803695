import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Link} from 'react-router-dom'

import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Breadcrumb, Card,Select
 
} from 'antd';

import { StopOutlined, CheckOutlined  } from '@ant-design/icons';
import {useSelector, useDispatch} from 'react-redux'
import {setEditData, updateClient,  clearEditMode, clearCreation} from '../actions/clientAction'
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};


const UpdateClient = (props) => {


  useEffect(() => {

    dispatch(setEditData(props.match.params.id))
   
  }, []);



const dispatch = useDispatch()
const submitLoader = useSelector(state => state.createClientReducer.loader)
const submitResult = useSelector(state => state.createClientReducer.result)

const resultData = useSelector(state => state.clientEditModeReducer.data)


  const [form] = Form.useForm();
  const [formTouched, setFormTouched] = useState(false);
  const [HelpdeskUrl, setHelpdeskUrl] = useState("");
  const [BackupPolicy, setBackupPolicy] = useState("");
  const [specialrequests, setSpecialrequests] = useState("");
  const [termsandConditions, setTermsandConditions] = useState("");
  const [operatingsystem, setOperatingsystem] = useState("");
  const [servers_list, setServerslist] = useState("");
  const [data_helpdeskurl, setDatahelpdeskurl] = useState("");
  const [nameservers, setNameservers] = useState("");
  const [monitoring_status, setMonitoringstatus] = useState("");
  const [other_details, setOtherdetails] = useState("");
  const [escalation_procedure, setEscalation_procedure] = useState("");

  const handleValueChange = (changedValues, allValues)=>{
    setFormTouched(true)

  }

  const onFinish = values => {

let submitValues={
...values,
HelpdeskUrl,
BackupPolicy,
specialrequests,
termsandConditions,
operatingsystem,
servers_list,
data_helpdeskurl,
nameservers,
monitoring_status,
other_details,
escalation_procedure
}


  dispatch(updateClient(submitValues))
 



  };


  useEffect(
    () => {

    
    
      form.setFieldsValue(resultData)
      
        setHelpdeskUrl(resultData.HelpdeskUrl?String(resultData.HelpdeskUrl):'')
        setBackupPolicy(resultData.BackupPolicy?String(resultData.BackupPolicy):'')
        setSpecialrequests(resultData.specialrequests?String(resultData.specialrequests):'')
        setTermsandConditions(resultData.termsandConditions?String(resultData.termsandConditions):'')
        setOperatingsystem(resultData.operatingsystem?String(resultData.operatingsystem):'')
        setServerslist(resultData.servers_list?String(resultData.servers_list):'')
        setDatahelpdeskurl(resultData.data_helpdeskurl?String(resultData.data_helpdeskurl):'')
        setNameservers(resultData.nameservers?String(resultData.nameservers):'')
        setMonitoringstatus(resultData.monitoring_status?String(resultData.monitoring_status):'')
        setOtherdetails(resultData.other_details?String(resultData.other_details):'')
        setEscalation_procedure(resultData.escalation_procedure?String(resultData.escalation_procedure):'')

  
    
    

    


    
    },[resultData]
    
  );

  useEffect(
    () => {

     
      if(submitResult==="Client updated successfully"){
        form.resetFields();
      
        setHelpdeskUrl("")
        setBackupPolicy("")
        setSpecialrequests("")
        setTermsandConditions("")
        setOperatingsystem("")
        setServerslist("")
        setDatahelpdeskurl("")
        setNameservers("")
        setMonitoringstatus("")
        setOtherdetails("")
        setEscalation_procedure("")
        props.history.push('/clients')
    
      }

      return () =>  dispatch(clearCreation());


    
    },[submitResult]
    
  );


  


  return (
    <div className="updateClients">
     
    <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 },16]}>
  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
    
  <div className="filterArea">

  <Card  bordered={false}  style={{"height":"100%", "marginTop":"16px"}} >
  <Row>
  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
  <Breadcrumb >
          <Breadcrumb.Item>Admin</Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/clients">Clients</Link></Breadcrumb.Item>
          <Breadcrumb.Item>Update</Breadcrumb.Item>
        </Breadcrumb>




      </Col>
  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
  <Link to="/clients"><Button type="primary" style={{float: 'right'}}>List</Button>
</Link>



      </Col>
  </Row>

      </Card>

   

</div>

    
  </Col>
  </Row>

    
<Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 },24]}>
  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
  
    <Card  bordered={false} loading={false} style={{"height":"100%"}} >
    <Form
      {...formItemLayout}
      form={form}
      name="create-client"
      onFinish={onFinish}
      onValuesChange={handleValueChange}
      scrollToFirstError
     
    >

<Form.Item
        name="id"
        hidden={true}
      >
        <Input />
      </Form.Item>
<Form.Item
        name="Name"
        label={
          <span>
            Name
            
          </span>
        }
        rules={[{ required: true, message: 'Please input Name!', whitespace: true }]}
      >
        <Input />
      </Form.Item>
<Form.Item
        name="Address"
        label={
          <span>
            Address
            
          </span>
        }
        rules={[{ required: false, message: 'Please input Address!', whitespace: true }]}
      >
       <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="Email"
        label="E-mail"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input E-mail!',
          },
        ]}
      >
        <Input />
      </Form.Item>
<Form.Item
        name="Phone"
        label={
          <span>
            Phone
            
          </span>
        }
        rules={[{ required: false, message: 'Please input phone number!', whitespace: true }]}
      >
        <Input />
      </Form.Item>

    <Form.Item
        label={
          <span>
            Help Desk Url
            
          </span>
        }>
        <ReactQuill theme="snow" value={HelpdeskUrl}  onChange={setHelpdeskUrl} />
    </Form.Item>

    <Form.Item
        label={
          <span>
            Backup Policy
            
          </span>
        }>
        <ReactQuill theme="snow" value={BackupPolicy} onChange={setBackupPolicy} />
    </Form.Item>

    <Form.Item
        label={
          <span>
            Special Requests
            
          </span>
        }>
        <ReactQuill theme="snow" value={specialrequests} onChange={setSpecialrequests} />
    </Form.Item>

    <Form.Item
        label={
          <span>
            Terms & Conditions
            
          </span>
        }>
        <ReactQuill theme="snow" value={termsandConditions} onChange={setTermsandConditions} />
    </Form.Item>

    <Form.Item
        label={
          <span>
            Operating System
            
          </span>
        }>
        <ReactQuill theme="snow" value={operatingsystem} onChange={setOperatingsystem} />
    </Form.Item>

    <Form.Item
        label={
          <span>
            Server List
            
          </span>
        }>
        <ReactQuill theme="snow" value={servers_list} onChange={setServerslist} />
    </Form.Item>

    <Form.Item
        label={
          <span>
           Data Helpdesk Url
            
          </span>
        }>
        <ReactQuill theme="snow" value={data_helpdeskurl} onChange={setDatahelpdeskurl} />
    </Form.Item>

    <Form.Item
        label={
          <span>
          Nameservers
            
          </span>
        }>
        <ReactQuill theme="snow" value={nameservers} onChange={setNameservers} />
    </Form.Item>

    <Form.Item
        label={
          <span>
          Monitoring Status
            
          </span>
        }>
        <ReactQuill theme="snow" value={monitoring_status} onChange={setMonitoringstatus} />
    </Form.Item>

    <Form.Item
        label={
          <span>
         Other Details
            
          </span>
        }>
        <ReactQuill theme="snow" value={other_details} onChange={setOtherdetails} />
    </Form.Item>

    <Form.Item
        label={
          <span>
        Escalation Procedure
            
          </span>
        }>
        <ReactQuill theme="snow" value={escalation_procedure} onChange={setEscalation_procedure} />
    </Form.Item>
    <Form.Item 
      label="Status"
      name="status"
    
      rules={[{ required: true, message: 'Please select status!' }]}
      >
          <Select
 optionLabelProp="label"
       
          >
            <Option key={1} value={1} label={"Active"} >
              <div className="demo-option-label-item">
        <span role="img" aria-label="Active">
        <CheckOutlined />
        </span>
         Active
      </div>
      </Option>
            <Option key={0} value={0} label={"Inactive"} >
              
            <div className="demo-option-label-item">
        <span role="img" aria-label="Inactive">
        <StopOutlined />
        </span> 
         Inactive
      </div>
            </Option>

          
          </Select>
        </Form.Item>


    
    
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submitLoader}>
       Save
        </Button>
      </Form.Item>            
    </Form>

  
    </Card>
  </Col>

 
</Row>
    
</div>
    
  );
};
export default UpdateClient