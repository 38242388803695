import React from 'react';
import { Table, Input, Button, Space , Progress } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, SaveOutlined, DeleteOutlined, FormOutlined, StopOutlined, IssuesCloseOutlined, LoadingOutlined } from '@ant-design/icons';
import './style.scss'
import moment from 'moment'
import { connect } from 'react-redux';
import {updateUser} from '../actions/userAction'


class UserList extends React.Component {

constructor(props){

  super(props)

 
}

handleStatusChange = (id, status)=>{

 this.props.changeStatus({id, status})
}

  state = {
    searchText: '',
    searchedColumn: '',
  };


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
            </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
            </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };



  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '40%',
        ...this.getColumnSearchProps('email'),
      },
     
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '20%',
        // ...this.getColumnSearchProps('status'),
        render: (id) =>{ 
         switch (id) {
           case 1:
             return "Active"
             break;
           case 0:
             return "Suspended"
             break;
    
         }
          
          }

      },
    
      {
        title: 'Action',
        dataIndex: ['id','status'],
        key: 'id',
        width: '10%',
        render: (id,status) => {
          if(status.status == 1){

            return (
              <Space size={5}>
              {/* <a href="#" title="Edit"><FormOutlined /></a>
           
              <a href="#" title="Delete"><DeleteOutlined /></a> */}
            
             {changeStatusLoader== status.id?<LoadingOutlined />:<a href="#" onClick={()=>this.handleStatusChange(status.id,0)} title="Suspend"><StopOutlined /></a>}
  
            </Space>
              
            )
          }else return(

            <Space size={5}>
            {/* <a href="#" title="Edit"><FormOutlined /></a>
         
            <a href="#" title="Delete"><DeleteOutlined /></a> */}
          
          {changeStatusLoader== status.id?<LoadingOutlined />: <a href="#" onClick={()=>this.handleStatusChange(status.id,1)} title="Activate"><IssuesCloseOutlined /></a>}

          </Space>
          )
         
          
        
        }
      },
    ];

    const {usersList, changeStatusLoader} = this.props
    return <Table columns={columns} dataSource={usersList} size="small" bordered pagination={{ hideOnSinglePage:true} ,{pageSize:5}} />;
  }
}

const mapStateToProps = state => {
  return {
  
    changeStatusLoader: state.updateUserReducer.loader,
    changeStatusError: state.updateUserReducer.error,
  }
}
const mapDispatchToProps = dispatch => ({
  changeStatus: (data) => dispatch(updateUser(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(UserList)