
import React from 'react'
import {Row, Col, Breadcrumb, Card, Statistic, Switch, Radio ,Dropdown, Menu,  Select, Button , Checkbox } from 'antd';
import {
  GroupOutlined,
  WalletOutlined,
CreditCardOutlined,
CarryOutOutlined,
TeamOutlined,
EllipsisOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import {getUserList} from '../actions/userAction'

import jwt_decode from 'jwt-decode'
import UserList from './userList';
import CreateUser from './createUser';
class userContainer extends React.Component{
  constructor(props) {
    super(props)

    this.state = {
      list:0
    }

   
  
    
   
  }

 

  componentDidMount(){

    let tkn = localStorage.getItem('myToken')
    if(tkn){
      // let decoded  =  jwt_decode(tkn)
    }

    this.props.getUserList()
   

   
  }


render(){
const {usersList, usersListLoader } = this.props



return(

    <div className="users">
     
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 },16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        
      <div className="filterArea">
    
      <Card  bordered={false}  style={{"height":"100%", "marginTop":"16px"}} >
      <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      <Breadcrumb >
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Users</Breadcrumb.Item>
            </Breadcrumb>


  

          </Col>
      </Row>

          </Card>

       

  </div>
 
        
      </Col>
      </Row>
  
        
    <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 },24]}>
      <Col xs={24} sm={24} md={24} lg={14} xl={14}>
      
        <Card  bordered={false} loading={usersListLoader} style={{"height":"100%"}} >
       <UserList usersList = {usersList} />
  
      
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <Card  bordered={false} loading={false} style={{"height":"100%"}}>
   
<CreateUser />

        </Card>
      </Col>
     
    </Row>
        
    </div>


  
)





}


}
const mapStateToProps = state => {
  return {
    usersList: state.usersReducer.result,
    usersListLoader: state.usersReducer.loader,
    usersListError: state.usersReducer.error,
  }
}
const mapDispatchToProps = dispatch => ({
  getUserList: () => dispatch(getUserList()),
})
export default connect(mapStateToProps, mapDispatchToProps)(userContainer)