import React from 'react';
import { List, Avatar, Space , Progress } from 'antd';

import { SearchOutlined, SaveOutlined, DeleteOutlined, ShoppingOutlined, CloseSquareOutlined, MessageOutlined, LikeOutlined, StarOutlined  } from '@ant-design/icons';
import './style.scss'
import moment from 'moment'
import LiveProgress from './liveProgress';


const trimMsg = (message)=>{

  if (message && message.length > 250) {
    return message.substring(0, 250) + '...';
  } else return message


}

const IconText = ({ icon, text }) => (
  <span>
    {React.createElement(icon, { style: { marginRight: 8 } })}
    {text}
  </span>
);





class RightList extends React.Component {








  render() {
    const {listData} = this.props

    return(
<List
    itemLayout="vertical"
    size="large"
    pagination={{
      onChange: page => {
        console.log(page);
      },
      pageSize: 4,
    }}
    dataSource={listData}
   
    renderItem={item => (
      <List.Item
      key={item.title}
      actions={[
        
        <IconText icon={CloseSquareOutlined}  key="list-vertical-close" />,
      ]}
    
    >
      <List.Item.Meta
       
        title={<span>{item.mailsubject }</span>}
        description={item.clientname}
      />
       {/* {trimMsg(item.mailmessage)} */}
      {item.mailmessage}
    </List.Item>
    )}
  />

    ) ;
  }
}

export default RightList