
import {CHANGE_LIST_STATUS  } from '../helpers/constants'


export default (state = {list:0, assigned:["0"]}, action) => {
    switch (action.type) {
        case CHANGE_LIST_STATUS:
            return {
                list:action.payload.list,
                assigned:action.payload.assigned,
              
            }
        default:
            return state
    }
}