
import { TICKET_FETCH_BEGINS, TICKET_FETCH_SUCCESS, TICKET_FETCH_FAILURE, STATUS_CHANGE_SUCCESS } from '../helpers/constants'


export default (state = {}, action) => {
    switch (action.type) {
        case TICKET_FETCH_BEGINS:
            return {
                loader: true,
                result: null,
                ticketsright:[],
                ticketsleft:[],
                usersList:[],
                ticketsleftCount:0,
                ticketsOpenCount:0,
                ticketNewCount:0,
                error: null
            }
        case TICKET_FETCH_SUCCESS:
            return {
                loader: false,
                result: action.payload,
                ticketsright:action.payload.ticketsright,
                ticketsleft:action.payload.ticketsleft,
                usersList:action.payload.users,
                ticketsleftCount:action.payload.ticketsleftCount,
                ticketsOpenCount:action.payload.ticketsOpenCount,
                ticketNewCount:action.payload.ticketNewCount,
                error: null
            }
        case TICKET_FETCH_FAILURE:
            return {
                loader: false,
                result: null,
                ticketsright:[],
                ticketsleft:[],
                usersList:[],
                ticketsleftCount:0,
                ticketsOpenCount:0,
                ticketNewCount:0,
                error: action.payload,
            }
        case STATUS_CHANGE_SUCCESS:

            return {
                 loader: false,
                result: action.payload,
                ticketsright:state.ticketsright.filter((item)=>item.id!=action.payload),
                ticketsleft:state.ticketsleft.filter((item)=>item.id!=action.payload),
                usersList:state.usersList,
                ticketsleftCount:state.ticketsleftCount,
                ticketsOpenCount:state.ticketsOpenCount,
                ticketNewCount:state.ticketNewCount,
                error: null
            }
        default:
            return state
    }
}