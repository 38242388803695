
import {  CLOSE_TICKETS_BEGINS, CLOSE_TICKETS_SUCCESS, CLOSE_TICKETS_FAILURE } from '../helpers/constants'


export default (state = {}, action) => {
    switch (action.type) {
        case CLOSE_TICKETS_BEGINS:
            return {
                loader:true,
                result: null,
                error: null
            }
        case CLOSE_TICKETS_SUCCESS:
            return {
                loader: false,
                result: action.payload,
                error: null
            }
        case CLOSE_TICKETS_FAILURE:
            return {
                loader: false,
                result: null,
                error: action.payload,
            }
        default:
            return state
    }
}