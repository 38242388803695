import React, { useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {useSelector, useDispatch} from 'react-redux'
import {createUser} from '../actions/userAction'





const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const CreateUser = () => {
const dispatch = useDispatch()
const submitLoader = useSelector(state => state.createUserReducer.loader)

  const [form] = Form.useForm();

  const onFinish = values => {


    dispatch(createUser(values))
    setTimeout(() => {
      form.resetFields();
      
    }, 2000);

  };



  const [autoCompleteResult, setAutoCompleteResult] = useState([]);


  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
     
      scrollToFirstError
    >

<Form.Item
        name="name"
        label={
          <span>
            Name
            
          </span>
        }
        rules={[{ required: true, message: 'Please input name!', whitespace: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input E-mail!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item 
      label="Role"
      name="designation_id"
      rules={[{ required: true, message: 'Please select role!' }]}
      >
          <Select>
            <Select.Option value="1">Admin</Select.Option>
            <Select.Option value="2">Sys.Admin</Select.Option>
            <Select.Option value="3">Team Lead</Select.Option>
          </Select>
        </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
           
            message: 'Please input password!',
          },
          { min: 6, message: 'Password must be minimum 6 characters.' },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>


    
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submitLoader}>
          Register
        </Button>
      </Form.Item>
    </Form>
  );
};
export default CreateUser