import React from 'react';
import { history } from './helpers/history'
import {Router} from "react-router-dom";
import RouterComponent from './routes/RouterComponent';
class App extends React.Component {

  render(){
  return (
    <Router history={history}>
  <RouterComponent  />
    </Router>

  );
}
}

export default App;
