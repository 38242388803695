import React from 'react';
import { Layout, Menu, Dropdown, message } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  WarningOutlined,
  DashboardOutlined,
  TeamOutlined
} from '@ant-design/icons';
import './style.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, NavLink
} from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';

const { Header, Sider, Content, Footer } = Layout;


const onClick = ({ key }) => {
  // message.info(`Click on item ${key}`);
};

const menu = (
  <Menu onClick={onClick}>
    <Menu.Item key="1"><Link to="/login">Logout</Link> </Menu.Item>
    {/* <Menu.Item key="2">2nd memu item</Menu.Item>
    <Menu.Item key="3">3rd menu item</Menu.Item> */}
  </Menu>
);
class LayoutContainer extends React.Component {

  constructor(props) {


    super(props)

    this.state = {
      collapsed: true,
      selectedKeys:[],
      userName:'',
      userRole:''
    };

    this.selectMenu = this.selectMenu.bind(this)

 
  }

  componentDidMount(){
const {children} = this.props
let uname=  localStorage.getItem('user')
let urole=  localStorage.getItem('role')

if(uname){
  this.setState({
    userName:uname,
    userRole:urole
  })
}

switch (children.props.history.location.pathname) {
  case '/dashboard':
  this.setState({
    selectedKeys:["1"]
   
  })
  break;
  case '/':
  this.setState({
    selectedKeys:["1"]
  })
  break;
  case '/users':
  this.setState({
    selectedKeys:["2"]
  })
    
    break;
  case '/clients':
  this.setState({
    selectedKeys:["3"]
  })
    
    break;
  case '/create-client':
  this.setState({
    selectedKeys:["3"]
  })
    
    break;
}


  }

  selectMenu = (e)=>{

this.setState({
  selectedKeys:e.keyPath
})

  }



  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };




  render() {
    const {userName, userRole} = this.state
    return (

      <Layout style={{ minHeight: '100vh' }}>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="siteLogo" ></div>
          <Menu theme="dark" mode="inline"  selectedKeys	={this.state.selectedKeys} onSelect = {this.selectMenu}   >
            <Menu.Item key="1"  >
              <Link to="/dashboard">
                <DashboardOutlined />
                <span>Dashboard</span>
              </Link>
            </Menu.Item>
           {userRole && userRole==1? <Menu.Item key="2">
              <Link to="/users">
                <UserOutlined />
                <span>Users</span>
              </Link>
            </Menu.Item>:''}
           {userRole && userRole==1?  <Menu.Item key="3">
              <Link to="/clients">
              <TeamOutlined />
                <span>Clients</span>
              </Link>
            </Menu.Item>:''}
            {/* <Menu.Item key="2">
              <Link to="/dashboard-v2">
                <DashboardOutlined />
                <span>V2</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/dashboard-v3">
                <DashboardOutlined />
                <span>V3</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/dashboard-v4">
                <DashboardOutlined />
                <span>V4</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/page-not-found">
                <WarningOutlined />
                <span>404</span>
              </Link>
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}


            <Dropdown overlay={menu} >
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ "float": "right", "padding": "0 5%" }}>

                <UserOutlined />&nbsp;
              {userName&& <span>{userName}</span> }
              </a>
            </Dropdown>
          </Header>
          <Content

            style={{
              margin: '0 16px',

            }}
          >

            {this.props.children}



          </Content>

          <Footer style={{ textAlign: 'center' }}>Logic Support ©2020 Created by Vtrio</Footer>
        </Layout>
      </Layout>


    );
  }
}

export default LayoutContainer