import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import LayoutContainer from '../frameLayouts/LayoutContainer';


const listofPages = [
    '/login'
];
let innerPage = false
let authStatus = false
authStatus = localStorage.getItem('myStatus')

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={((props) => {





        if (listofPages.indexOf(window.location.pathname) > -1) {
            innerPage = false
            localStorage.clear()

            // localStorage.setItem('myStatus', false)
            // localStorage.setItem('myToken', '')
        } else {

            innerPage = true;


        }






        if (innerPage && authStatus === 'logged_in') {

            return <LayoutContainer><Component {...props} /></LayoutContainer>
        } else return <Redirect to='/login' />


    })} />
)