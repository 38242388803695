import React, { useEffect, useState } from 'react';
import {  Progress } from 'antd';

function LiveProgress(props) { 
    
    const {postedDate} = props
    // const postedDate = "2020-04-17 16:36:00"


    const calculateTimeLeft =() =>{

        const twoN = (n)=>{
            return n > 9 ? "" + n: "0" + n;
        }

        const difference = +new Date() - +new Date(postedDate);
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
            //   days: Math.floor(difference / (1000 * 60 * 60 * 24)),
              hours: Math.floor((difference / (1000 * 60 * 60))),
              minutes: twoN(Math.floor((difference / 1000 / 60) % 60)),
              seconds: twoN(Math.floor((difference / 1000) % 60))
            };
          }
      
          return timeLeft;
        };


        const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

        useEffect(() => {
            setTimeout(() => {
              setTimeLeft(calculateTimeLeft());
            }, 1000);
          });


          const timerComponents = [];

          Object.keys(timeLeft).forEach(interval => {
            if (!timeLeft[interval]) {
            //   return;
            timerComponents.push(
                <span>
                  {"0"}
                </span>
              );
            }
        
            timerComponents.push(
              <span>
                {timeLeft[interval]}{interval!="seconds"?":":''}
              </span>
            );

           
          });

          const  progress = (timeLeft['hours']*60+timeLeft['minutes'])
          const perCent = Math.round(( progress/60)*100)
let strokeColor = "#009900"

if(perCent>=100){
    strokeColor = "#ff0000"

}else if(perCent>=95){
    strokeColor = "#ff6600"

}else if(perCent>=75){
    strokeColor = "#ff9933"

}else if(perCent>=50){
    strokeColor = "#ffcc00"

} else if(perCent>=25){
    strokeColor = "#2C76FF"

}



          return (
           
        //  <Progress percent={perCent}  status={progress>60?"exception":"active"} showInfo={false} strokeColor={strokeColor} trailColor={"#e3e5e8"}  />

       <div style = {{"width":"200px" ,"display":"flex"}} className="prgs">
<Progress percent={perCent}  status={progress>60?"exception":"active"} showInfo={false} strokeColor={strokeColor} trailColor={"#e3e5e8"}   />
     {timerComponents.length ? timerComponents : <span>Time's up!</span>}
   </div> 
             
          );
}

export default LiveProgress
