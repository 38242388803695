
import { LOGIN_BEGINS, LOGIN_SUCCESS, LOGIN_FAILURE } from '../helpers/constants'


export default (state = {}, action) => {
    switch (action.type) {
        case LOGIN_BEGINS:
            return {
                loader: true,
                result: null,
                error: null
            }
        case LOGIN_SUCCESS:
            return {
                loader: false,
                result: action.payload,
                error: null
            }
        case LOGIN_FAILURE:
            return {
                loader: false,
                result: null,
                error: action.payload,
            }
        default:
            return state
    }
}