import React from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Alert, Space } from 'antd';
import { Layout } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './style.scss'
import { connect } from 'react-redux';
import { doLogin } from '../actions/auth/authAction'
const { Content, Footer } = Layout;


class LoginComponent extends React.Component {

  constructor(props) {
    super(props)
    this.onFinish = this.onFinish.bind(this)

  }

  componentWillMount(){
    localStorage.clear()
    // localStorage.setItem('myStatus', false)
    // localStorage.setItem('myToken', '')
  }


  onFinish = values => {

    console.log('Received values of form: ', values);
    this.props.doLogin(values)

  };

  render() {
const {loginResult, loginError, loginLoader} = this.props
    return (
      <Layout style={{"height":"100vh"}}>
        <Content  >
        <Row>
<Col xs={24} sm={24} md={24} lg={24} xl={24}>

  <div className="login-container">

    <h2 style={{textAlign:"center"}} >Log In</h2>

 <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
              >
                {loginError&& <Form.Item>
                 <Alert message={loginError} type="error" showIcon  />
                 </Form.Item>}
                
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Username!',
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Password!',
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                {/* <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  <a className="login-form-forgot" href="/">
                    Forgot password
        </a>
                </Form.Item> */}

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button" disabled={loginLoader} >
                  {!loginLoader? 'Log in':'Please wait...'}
        </Button>

                </Form.Item>
                <Form.Item>
               

              
                </Form.Item>
              </Form>

            

  </div>

 


       


</Col>




</Row>
        </Content>

      </Layout>



    );
  }
};

const mapStateToProps = state => {
  return {


    loginResult: state.authReducer.result,
    loginError: state.authReducer.error,
    loginLoader: state.authReducer.loader,

  }
}
const mapDispatchToProps = dispatch => ({
  doLogin: (data) => dispatch(doLogin(data)),


})
export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent)