
import {  CREATE_USER_BEGINS, CREATE_USER_SUCCESS, CREATE_USER_FAILURE } from '../helpers/constants'


export default (state = {}, action) => {
    switch (action.type) {
        case CREATE_USER_BEGINS:
            return {
                loader:true,
                result: null,
                error: null
            }
        case CREATE_USER_SUCCESS:
            return {
                loader: false,
                result: action.payload,
                error: null
            }
        case CREATE_USER_FAILURE:
            return {
                loader: false,
                result: null,
                error: action.payload,
            }
        default:
            return state
    }
}