import { USER_FETCH_BEGINS, USER_FETCH_SUCCESS, USER_FETCH_FAILURE,
    CREATE_USER_BEGINS, CREATE_USER_SUCCESS, CREATE_USER_FAILURE ,  UPDATE_USER_BEGINS, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE } from '../helpers/constants'
import { API_URL } from '../helpers/config'
import axios from 'axios';
import { history } from '../helpers/history'
import {  message } from 'antd';



export  const  getUserList = () =>  dispatch => {
    dispatch({ type: USER_FETCH_BEGINS });
   

       axios.post(API_URL + 'auth/userList',{},{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
    .then(res => {
            if (res.data.response === 'success') {
    
                 dispatch({
                    type: USER_FETCH_SUCCESS,
                    payload: res.data.message
                })
              

            } else {
            
                   dispatch({
                    type: USER_FETCH_FAILURE,
                    payload: res.data
                })
                 message.info(`Some error occurred. Please try after some time`);
            }
        })
        .catch(error => {  dispatch({
            type: USER_FETCH_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}
export const createUser = (postData) => dispatch => {
    dispatch({ type: CREATE_USER_BEGINS });
   

    axios.post(API_URL + 'auth/register',postData,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
        .then(res => {
            if (res.data.status === 'success') {
    
                dispatch({
                    type: CREATE_USER_SUCCESS,
                    payload: res.data.message,
                   
                })
                message.info(`${res.data.message}`);
                setTimeout(() => {
                    dispatch(getUserList())
                    
                });

            } else {
            
                dispatch({
                    type: CREATE_USER_FAILURE,
                    payload: res.data.message
                })
                if(res.data.message && res.data.message.email){
                    message.info(`${res.data.message.email[0]}`);

                }else{
                    message.info(`Registration failed`);
                }
            }
        })
        .catch(error => {dispatch({
            type: CREATE_USER_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}

export const updateUser = (postData) => dispatch => {
    dispatch({ type: UPDATE_USER_BEGINS, payload:postData.id });
   

    axios.post(API_URL + 'auth/updateUser',postData,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
        .then(res => {
            if (res.data.status === 'success') {
    
                dispatch({
                    type: UPDATE_USER_SUCCESS,
                    payload: res.data.message,
                   
                })
                message.info(`${res.data.message}`);
                setTimeout(() => {
                    dispatch(getUserList())
                    
                });

            } else {
            
                dispatch({
                    type: UPDATE_USER_FAILURE,
                    payload: res.data.message
                })
                message.info(`${res.data.message}`);
            }
        })
        .catch(error => {dispatch({
            type: UPDATE_USER_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}

