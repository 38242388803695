import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import LoginComponent from "../loginComponent/LoginComponent";
import Dashboard from "../dashboard/dashboard";
import DashboardV2 from "../dashboardV2/dashboard";
import DashboardV3 from "../dashboardV3/dashboard";
import DashboardV4 from "../dashboardV4/dashboard";
import { PrivateRoute } from "./PrivateRoute";
import NotFound from "../errorPages/notFound";
import UserContainer from "../users/userContainer";
import ClientContainer from "../clients/clientContainer";
import ClientProfileContainer from "../clients/clientProfileContainer";
import  CreateClient  from "../clients/createClient";
import  UpdateClient  from "../clients/updateClient";

const listofPages = [
  '/login',
 
];

const RouterComponent = () => {

     if (listofPages.indexOf(window.location.pathname) > -1) {
        return (
            


          <Route exact path="/login" component={LoginComponent} />

          
        )
    }else

  return (

    <Switch>
     
      <PrivateRoute exact path="/" component={DashboardV4} />
      <Route exact path="/login" component={LoginComponent} />
      <PrivateRoute exact path="/dashboard" component={DashboardV4} />
      <PrivateRoute exact path="/users" component={UserContainer} />
      <PrivateRoute exact path="/clients" component={ClientContainer} />
      <PrivateRoute exact path="/create-client" component={CreateClient} />
      <PrivateRoute exact path="/edit-client/:id" component={UpdateClient} />
      <PrivateRoute exact path="/profile/:id" component={ClientProfileContainer} />
      {/* <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
      {/* <PrivateRoute exact path="/dashboard-v2" component={DashboardV2} />
      
      <PrivateRoute exact path="/dashboard-v3" component={DashboardV3} />
      <PrivateRoute exact path="/dashboard-v4" component={DashboardV4} /> */}

      
      <PrivateRoute component={NotFound} />
    </Switch>


  )

}
export default RouterComponent