
import { STATUS_CHANGE_BEGINS, STATUS_CHANGE_SUCCESS, STATUS_CHANGE_FAILURE } from '../helpers/constants'


export default (state = {}, action) => {
    switch (action.type) {
        case STATUS_CHANGE_BEGINS:
            return {
                loader:action.payload,
                result: null,
                error: null
            }
        case STATUS_CHANGE_SUCCESS:
            return {
                loader: false,
                result: action.payload,
                error: null
            }
        case STATUS_CHANGE_FAILURE:
            return {
                loader: false,
                result: null,
                error: action.payload,
            }
        default:
            return state
    }
}