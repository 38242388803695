import React from 'react';
import { List, Avatar, Space , Progress } from 'antd';

import { SearchOutlined, SaveOutlined, DeleteOutlined, ShoppingOutlined, CloseSquareOutlined, MessageOutlined, LikeOutlined, StarOutlined  } from '@ant-design/icons';
import './style.scss'
import moment from 'moment'
import LiveProgress from './liveProgress';


const trimMsg = (message, length)=>{

  if (message && message.length > length) {
    return message.substring(0, length-1) + '...';
  } else return message


}

const IconText = ({ icon, text }) => (
  <span>
    {React.createElement(icon, { style: { marginRight: 2, fontSize:15 } })}
    {text}
  </span>
);


class LeftList extends React.Component {



  render() {
const {listData} = this.props
    return(
<List
    itemLayout="vertical"
    size="large"
    split ="true"
    pagination={{
      onChange: page => {
        console.log(page);
      },
      pageSize: 4,
    }}
    dataSource={listData}
   
    renderItem={item => (
      <List.Item
        key={item.title}
        actions={[
         
          <IconText icon={SaveOutlined}  key="list-vertical-star-o" />,
          <IconText icon={DeleteOutlined}  key="list-vertical-like-o" />,
          <IconText icon={ShoppingOutlined}  key="list-vertical-message" />,
          <IconText icon={CloseSquareOutlined}  key="list-vertical-close" />,
          <LiveProgress postedDate ={item.date} key ="pgbar" />,
          
        ]}
      
      >
      
        <List.Item.Meta
         avatar = {<Avatar>ACK</Avatar>}
         title={<span>{item.mailsubject } </span>}
          // description={item.clientname}
        />
      
      {trimMsg(item.mailmessage,150)}

      </List.Item>
    )}
  />

    ) ;
  }
}

export default LeftList