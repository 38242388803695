
import React from 'react'
import {Row, Col, Divider, Breadcrumb, Card, Statistic } from 'antd';
import {
  ArrowUpOutlined,
  WalletOutlined,
CreditCardOutlined,
CarryOutOutlined,
TeamOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import {getTickets} from '../actions/dashBoardAction'
import LeftList from './leftList';
import RightList from './rightList';
class dashboardV3 extends React.Component{
  constructor(props) {
    super(props)
   
  }


  componentDidMount(){


    this.props.getTickets()
  }


render(){
const {ticketsleftCount, leftTicketResult, rightTicketResult, ticketLoader } = this.props
return(

    <div className="dash-v3">
  <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Dashboard-v3</Breadcrumb.Item>
            </Breadcrumb>

        
    <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 },24]}>
      <Col xs={24} sm={24} md={24} lg={14} xl={14}>
        <Card  bordered={false} loading={ticketLoader} style={{"height":"100%"}} >
       <LeftList listData = {leftTicketResult} />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <Card  bordered={false} loading={ticketLoader} style={{"height":"100%"}}>
      <RightList listData = {leftTicketResult} />
        </Card>
      </Col>
     
    </Row>
  
  

  <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 },24]}>
      <Col span={6}>
        <Card  bodyStyle={{background:"#ff7875"}} loading={ticketLoader} >
        <Statistic
            title="New Tickets"
            value={ticketsleftCount}
            precision={2}
            valueStyle={{ color: '#fff' }}
            prefix={<CreditCardOutlined />}
            suffix=""
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card  bodyStyle={{background:"#73d13d"}} loading={ticketLoader}>
        <Statistic
            title="Closed Tickets within SLA"
            value={11.28}
            precision={2}
            valueStyle={{ color: '#fff' }}
            prefix={<CarryOutOutlined />}
            suffix="%"
          />
       
        </Card>
      </Col>
      <Col span={6}>
        <Card  bordered={false}  bodyStyle={{background:"#5cdbd3"}} loading={ticketLoader}>
        <Statistic
            title="Closed Tickets out SLA"
            value={11.28}
            precision={2}
            valueStyle={{ color: '#fff' }}
            prefix={<WalletOutlined />}
            suffix="%"
          />
       
        </Card>
      </Col>
      <Col span={6}>
        <Card  bordered={false}  bodyStyle={{background:"#b37feb"}} loading={ticketLoader}>
        <Statistic
            title="Client"
            value={11}
            precision={2}
            valueStyle={{ color: '#fff' }}
            prefix={<TeamOutlined />}
            suffix=""
          />
        </Card>
      </Col>
     
     
    </Row>


        
    </div>


  
)





}


}
const mapStateToProps = state => {
  return {


    leftTicketResult: state.dashTicketReducer.ticketsleft,
    rightTicketResult: state.dashTicketReducer.ticketsright,
    ticketsleftCount: state.dashTicketReducer.ticketsleftCount,



    ticketError: state.dashTicketReducer.error,
    ticketLoader: state.dashTicketReducer.loader,

  }
}
const mapDispatchToProps = dispatch => ({
  getTickets: () => dispatch(getTickets()),


})
export default connect(mapStateToProps, mapDispatchToProps)(dashboardV3)