
import {  CLIENT_DETAILS_EDIT_SUCCESS, CLIENT_DETAILS_EDIT_CLEAR } from '../helpers/constants'


export default (state = {mode:false, data:{}}, action) => {
    switch (action.type) {
        case CLIENT_DETAILS_EDIT_SUCCESS:
            return {
                mode:true,
                data:  action.payload.client
               
            }
        case CLIENT_DETAILS_EDIT_CLEAR:
            return {
                mode: false,
                data: {},
              
            }
      
        default:
            return state
    }
}