import { 
     CLIENT_FETCH_BEGINS,
     CLIENT_FETCH_SUCCESS,
     CLIENT_FETCH_FAILURE,
     CLIENT_DETAILS_FETCH_BEGINS,
     CLIENT_DETAILS_FETCH_SUCCESS,
     CLIENT_DETAILS_FETCH_FAILURE,
    CREATE_CLIENT_BEGINS, CREATE_CLIENT_SUCCESS, CREATE_CLIENT_FAILURE ,  UPDATE_CLIENT_BEGINS, UPDATE_CLIENT_SUCCESS, UPDATE_CLIENT_FAILURE, CLIENT_DETAILS_EDIT_BEGINS, CLIENT_DETAILS_EDIT_SUCCESS, CLIENT_DETAILS_EDIT_FAILURE, CLIENT_DETAILS_EDIT_CLEAR, CREATE_CLIENT_CLEAR } from '../helpers/constants'
import { API_URL } from '../helpers/config'
import axios from 'axios';
import { history } from '../helpers/history'
import {  message } from 'antd';



export  const  getClientList = () =>  dispatch => {
    dispatch({ type: CLIENT_FETCH_BEGINS });
   

       axios.post(API_URL + 'auth/listClients',{},{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
    .then(res => {
            if (res.data.status === 'success') {
    
                 dispatch({
                    type: CLIENT_FETCH_SUCCESS,
                    payload: res.data.clients
                })
              

            } else {
            
                   dispatch({
                    type: CLIENT_FETCH_FAILURE,
                    payload: res.data
                })
                 message.info(`Some error occurred. Please try after some time`);
            }
        })
        .catch(error => {  dispatch({
            type: CLIENT_FETCH_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}
export  const  getClientDetails = (id) =>  dispatch => {
    dispatch({ type: CLIENT_DETAILS_FETCH_BEGINS });
   

       axios.post(API_URL + 'auth/clientDetail',{id},{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
    .then(res => {
            if (res.data.status === 'success') {
    
                 dispatch({
                    type: CLIENT_DETAILS_FETCH_SUCCESS,
                    payload: res.data
                })
              

            } else {
            
                   dispatch({
                    type: CLIENT_DETAILS_FETCH_FAILURE,
                    payload: res.data
                })
                 message.info(`Some error occurred. Please try after some time`);
            }
        })
        .catch(error => {  dispatch({
            type: CLIENT_FETCH_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}
export  const  setEditData = (id) =>  dispatch => {
    dispatch({ type: CLIENT_DETAILS_EDIT_BEGINS });
   

       axios.post(API_URL + 'auth/clientDetail',{id},{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
    .then(res => {
            if (res.data.status === 'success') {
    
                dispatch({
                    type: CLIENT_DETAILS_EDIT_SUCCESS,
                    payload: res.data
                })
              

            } else {
            
                   dispatch({
                    type: CLIENT_DETAILS_EDIT_FAILURE,
                    payload: res.data
                })
                 message.info(`Some error occurred. Please try after some time`);
            }
        })
        .catch(error => {  dispatch({
            type: CLIENT_DETAILS_EDIT_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}
// export  const  setEditData = (row) =>  dispatch => {
   
//     dispatch({
//         type: CLIENT_DETAILS_EDIT_SUCCESS,
//         payload: row
//     })

    
// }
export  const  clearEditMode = () =>  dispatch => {
   
    dispatch({
        type: CLIENT_DETAILS_EDIT_CLEAR
       
    })

    
}
export  const  clearCreation = () =>  dispatch => {
   
    dispatch({
        type: CREATE_CLIENT_CLEAR
       
    })

    
}
export const createClient = (postData) => dispatch => {
    dispatch({ type: CREATE_CLIENT_BEGINS });
   

    axios.post(API_URL + 'auth/createClient',postData,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
        .then(res => {
            if (res.data.status === 'success') {
    
                dispatch({
                    type: CREATE_CLIENT_SUCCESS,
                    payload: res.data.message,
                   
                })
                message.info(`${res.data.message}`);
                // setTimeout(() => {
                //     dispatch(getClientList())
                    
                // });

            } else {
            
                dispatch({
                    type: CREATE_CLIENT_FAILURE,
                    payload: res.data.message
                })
                if(res.data.message && res.data.message.email){
                    message.info(`${res.data.message.email[0]}`);

                }else{
                    message.info(`failed`);
                }
            }
        })
        .catch(error => {dispatch({
            type: CREATE_CLIENT_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}
export const updateClient = (postData) => dispatch => {
    dispatch({ type: CREATE_CLIENT_BEGINS });
   

    axios.post(API_URL + 'auth/updateClient',postData,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
        .then(res => {
            if (res.data.status === 'success') {
    
                dispatch({
                    type: CREATE_CLIENT_SUCCESS,
                    payload: res.data.message,
                   
                })
                message.info(`${res.data.message}`);

                setTimeout(() => {

                   dispatch(clearEditMode()) 
                    
                });
               

            } else {
            
                dispatch({
                    type: CREATE_CLIENT_FAILURE,
                    payload: res.data.message
                })
                if(res.data.message && res.data.message.email){
                    message.info(`${res.data.message.email[0]}`);

                }else{
                    message.info(`failed`);
                }
            }
        })
        .catch(error => {dispatch({
            type: CREATE_CLIENT_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}

// export const updateClient = (postData) => dispatch => {
//     dispatch({ type: UPDATE_CLIENT_BEGINS, payload:postData.id });
   

//     axios.post(API_URL + 'auth/updateUser',postData,{
//         headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
//     } )
//         .then(res => {
//             if (res.data.status === 'success') {
    
//                 dispatch({
//                     type: UPDATE_CLIENT_SUCCESS,
//                     payload: res.data.message,
                   
//                 })
//                 message.info(`${res.data.message}`);
//                 setTimeout(() => {
//                     dispatch(getClientList())
                    
//                 });

//             } else {
            
//                 dispatch({
//                     type: UPDATE_CLIENT_FAILURE,
//                     payload: res.data.message
//                 })
//                 message.info(`${res.data.message}`);
//             }
//         })
//         .catch(error => {dispatch({
//             type: UPDATE_CLIENT_FAILURE,
//             payload: 'API failed'
//         })
//         message.info(`Some error occurred. Please try after some time`);
//         history.push("/login")
//         }
        
//         );
// }

