
import React from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Breadcrumb, Card, Statistic, Switch, Radio ,Dropdown, Menu,  Select, Button , Checkbox } from 'antd';
import {
  GroupOutlined,
  WalletOutlined,
CreditCardOutlined,
CarryOutOutlined,
TeamOutlined,
EllipsisOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import {getClientList} from '../actions/clientAction'

import jwt_decode from 'jwt-decode'
import ClientList from './clientList';

class ClientContainer extends React.Component{
  constructor(props) {
    super(props)

    this.state = {
      list:0
    }

   
  
    
   
  }

 

  componentDidMount(){

    let tkn = localStorage.getItem('myToken')
    if(tkn){
      // let decoded  =  jwt_decode(tkn)
    }

    this.props.getClientList()
   

   
  }


render(){
const {clientsList, clientsListLoader } = this.props



return(

    <div className="users">
     
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 },16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        
      <div className="filterArea">
    
      <Card  bordered={false}  style={{"height":"100%", "marginTop":"16px"}} >
      <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <Breadcrumb >
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Clients</Breadcrumb.Item>
            </Breadcrumb>


  

          </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <Link to="/create-client"><Button type="primary" style={{float: 'right'}}>Create Client</Button>
</Link>

  

          </Col>
      </Row>

          </Card>

       

  </div>
 
        
      </Col>
      </Row>
  
        
    <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 },24]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      
        <Card  bordered={false} loading={clientsListLoader} style={{"height":"100%"}} >
       <ClientList clientsList = {clientsList} {...this.props} />
  
      
        </Card>
      </Col>
  
     
    </Row>
        
    </div>


  
)





}


}
const mapStateToProps = state => {
  return {
    clientsList: state.clientsReducer.result,
    clientsListLoader: state.clientsReducer.loader,
    clientsListError: state.clientsReducer.error,
  }
}
const mapDispatchToProps = dispatch => ({
  getClientList: () => dispatch(getClientList()),
})
export default connect(mapStateToProps, mapDispatchToProps)(ClientContainer)