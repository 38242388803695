
import {  TICKET_FETCH_SUCCESS } from '../helpers/constants'


export default (state = {}, action) => {
    switch (action.type) {
      
        case TICKET_FETCH_SUCCESS:
            return {
                usersList:action.payload.users,
             
            }
      
        default:
            return state
    }
}