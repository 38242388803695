import React, { useState, useRef, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { List, Avatar, Space ,Dropdown, Menu, Typography, Checkbox, Popover, Modal, Button,  } from 'antd';
import axios from 'axios';
import { API_URL } from '../helpers/config'
import moment from 'moment'


import { DeleteOutlined, ShoppingOutlined, CloseSquareOutlined, LoadingOutlined, UserSwitchOutlined, CheckOutlined, UserOutlined, EyeOutlined, CommentOutlined, NotificationOutlined } from '@ant-design/icons';
import './style.scss'
import LiveProgress from './liveProgress';
import {changeStatusAction, reAssignAction ,changeTrashIdsAction, commentTicket, replyTicket , fetchReplies} from '../actions/dashBoardAction'
import ReadMoreAndLess from 'react-read-more-less';
import ReadMoreReact from 'read-more-react';
const { Paragraph } = Typography;
function LeftList (props) {
  const changeStatusError = useSelector(state => state.changeStatusReducer.error)
  const changeStatusResult = useSelector(state => state.changeStatusReducer.result)
  const changeStatusLoading = useSelector(state => state.changeStatusReducer.loader)
  const listStatus = useSelector(state => state.listStatusReducer.list)


  const trashStatus = useSelector(state => state.trashIdsReducer.status)
  const assignedStatus = useSelector(state => state.listStatusReducer.assigned)
  const dispatch = useDispatch()

  const makeLogo = (name)=>{
    if (name) {
    let pos1 = name.indexOf('@')+1
    let pos2 = name.indexOf('@')+4
    if(name.length<pos2){
      pos2 =name.length+1
    }
      return name.substring(pos1,pos2).toUpperCase();
    } else return 'UNK'
  }
  const makeReplyLogo = (name)=>{
    if (name) {
   
      let nvalues = name.split(" ");
      console.log("cc", nvalues)
      let f_name = nvalues[0][0];
      let l_name = nvalues[1] ? name.substr(name.indexOf(' ') + 1,name.indexOf(' ') + 2) : '';
     
     return f_name+l_name.toUpperCase();
    } else return 'UNK'
  }

  const timeFormat = (date)=>{

    let stillUtc = moment.utc(date).toDate();
    let  local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');

    return local
  }

  const trimMsg = (message, length)=>{
    if (message && message.length > length) {
      return message.substring(0, length-1) + '...';
    } else return message
  }

 const stripHTML=(txt)=>{


    let tmp = document.createElement("DIV");
       tmp.innerHTML = txt;
       return    tmp.textContent||tmp.innerText ;
    
        
       }

  const [trashIds, settrashIds] = useState([]);
  const onChangeCheckbox = (e) => {
    if(e.target.checked){
      let tempIds = [...trashIds]
      tempIds.push(e.target.value)
      settrashIds(tempIds)
      dispatch(changeTrashIdsAction(tempIds))
    

    }else{
    let newTrashIds =   trashIds.filter((id)=>id!=e.target.value)
      settrashIds(newTrashIds)
      dispatch(changeTrashIdsAction(newTrashIds))

    }
  };
  const onClick = (id, status) => {
    dispatch(changeStatusAction({id,status, listStatus, assignedStatus}))
  };
  const assignToUser = (id, userid) => {
    dispatch(reAssignAction({id,userid, listStatus, assignedStatus}))
  };

const geenStyle={"color":"green"}
const {listData ,usersList} = props

const [pageSize, setPageSize] = useState(5);
const onShowSizeChange=(current, pageSize)=> {
  setPageSize(pageSize)


}


const assignedTo  = (id)=>{
 const {usersList} = props
  let assnName = ''
  usersList&& usersList.map((user)=>{
   if(user.id == id){
     assnName = user.name
   }
  })
  return assnName
}

const [visibleModal, changeVisibility] = useState(false);
const [visibleReplyModal, changeReplyVisibility] = useState(false);
const [commentText, setText] = useState('');
const [replyText, setReplyText] = useState('');
const [replyDatas, setReplyData] = useState([]);
const [commentTicketId, setCommentTicketId] = useState('');
const [replyTicketId, setReplyTicketId] = useState('');
const [highlightArray, sethighlightArray] = useState(["RWH"]);
const toggleModal = (id) => {
  changeVisibility(true)
  setCommentTicketId(id)

};
const toggleReplyModal =    (id) => {
  setReplyData([])
  changeReplyVisibility(true)
  setReplyTicketId(id)

 axios.post(API_URL + 'auth/listreplys',{id},{
  headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
} )
  .then(res => {
      if (res.data.status === 'success') {

        setReplyData(res.data.replays)
        
      } else {
          
            // message.info(`${res.data.message}`);
      }
  })
  .catch(error => {
      
  
      //  message.info(`Some error occurred. Please try after some time`);
        // history.push("/login")
  }
  
  );

};
const handleOk = (e) => {

let cmntData = {
  remarks:commentText,
  id:commentTicketId
}
dispatch(commentTicket(cmntData))
  changeVisibility(false)
  setTimeout(() => {
  setText("")
  }, 1000);

};
const handleCancel = (e) => {
  changeVisibility(false)
  setText("")



};
const hanndleChangeText = (e) => {
  setText(e.target.value)

};

const handleReply = (closeStatus) => {

let replyData = {
  replymessage:replyText,
  id:replyTicketId,
  closeStatus
}

dispatch(replyTicket(replyData))
changeReplyVisibility(false)
  setTimeout(() => {
  setReplyText("")
  }, 1000);

}
const handleReplyCancel = (e) => {
  changeReplyVisibility(false)
  setReplyText("")
  

};
const hanndleChangeReply = (e) => {
  setReplyText(e.target.value)

};



    return(
     
<div>
<List
    itemLayout="horizontal"
    size="small"
    // split ="true"
    pagination={{
      onChange: page => {
       
      },
      pageSize: pageSize,
      onShowSizeChange:onShowSizeChange,
      pageSizeOptions:['5','10','20','50','100'],
      hideOnSinglePage:true
    }}
    
    dataSource={listData}
   
    renderItem={item => (
      <List.Item
      className={ highlightArray.includes(makeLogo(item.clientname))?"highlighted":''}
        key={item.title}>
        <div class="ant-list-item-meta-avatar">
        <Avatar icon={<UserOutlined />} />
          <div className="logo-text">{ makeLogo(item.clientname)}</div>
          <Checkbox onChange={onChangeCheckbox} value= {item.id} key={item.id} checked= {trashIds.includes(item.id)} style={{"display":trashStatus?"inline":"none"}} />
       </div>
        <List.Item.Meta
         
         title={<div className="list-title-row">
           <a href={item.ticketurl?item.ticketurl:"#"} target="_blank">{stripHTML(trimMsg(item.mailsubject,55))}</a>
           <div className="title-right">
            <LiveProgress closedDate={item.closingDate} postedDate ={item.date} status={item.status} key ="pgbar" />
         {listStatus==0? <div style={{"paddingLeft":"15px", "paddingRight":"10px"}}>
        
        <img src={require("../images/img_205845.png")} style={{"height":"auto", "width":"18px", "cursor":"pointer", "paddingBottom":"5px"}} alt="assign to me" title="assign to me" onClick={()=>onClick(item.id, 1)} />
        
        </div>: <div style={{"paddingLeft":"10px", "paddingRight":"8px"}}>

        <Dropdown overlay={( <Menu >
          <Menu.Item key={`main`} onClick={()=>assignToUser(item.id, 0)}  >Main</Menu.Item>

          {usersList&& usersList.map((user)=>{

return   <Menu.Item key={`${user.name}`} onClick={()=>assignToUser(item.id, user.id)} disabled ={item.AssignedTo == user.id?true:false} > {user.name} {item.AssignedTo == user.id?<CheckOutlined style={{"color":"green"}} />:'' } </Menu.Item>


          })

          }
  </Menu>)} >

  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
 { <UserSwitchOutlined style={item.AssignedTo>0? geenStyle:{"border":"none"}}  key="list-vertical-star-o" title="re-assign" />}
  </a>
</Dropdown>
        </div>}
        <Dropdown overlay={( <Menu >
    
    <Menu.Item key={`${item.id}0`} onClick={()=>onClick(item.id, 0)} >
     <DeleteOutlined/>
       Delete
        </Menu.Item>

    <Menu.Item key={`${item.id}1`} onClick={()=>onClick(item.id, 2)} >
    <ShoppingOutlined/>
     
       Spam
        </Menu.Item>

    <Menu.Item key={`${item.id}2`} onClick={()=>onClick(item.id, 5)} >
    <CloseSquareOutlined/>
     
      Close
       </Menu.Item>
   
  </Menu>)} >
  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
 { changeStatusLoading!=item.id? 'More':<LoadingOutlined style={{"padding":"0 7px"}} />}
  </a>
</Dropdown>
         </div>
         </div>}
          description={<div>
       

<ReadMoreReact 
            key ={item.id}
            text={stripHTML(item.mailmessage)}
            min={50}
            ideal={200}
            max={2500}
            readMoreText="..."/>
      <Space size={10}>

      <Popover placement="right" content={()=>(<pre style={{"width":"750px","overflow":"auto"}}> {item.mailmessage}</pre>)} >
              {/* <EyeOutlined   /> */}
            <a>Read</a>
            </Popover> |
        
        {/* <CommentOutlined onClick={()=>toggleModal(item.id)} title="Add remarks" /> */}
        <a onClick={()=>toggleModal(item.id)} title="Add remarks" >Add Remarks </a>|
        
        {/* <NotificationOutlined onClick={()=>toggleReplyModal(item.id)} title="Reply" /> */}
        <a onClick={()=>toggleReplyModal(item.id)} title="Reply" >Reply</a>

      </Space>
           
             {item.AssignedTo>0? <span className="assignet-to">- {assignedTo(item.AssignedTo)}</span>:''}
             </div>}  
          
        />
      </List.Item>
    
    )}
 
  />


<Modal
          title="Add Remarks"
          visible={visibleModal}
          onOk={handleOk}
          okText ="Save"
          onCancel={handleCancel}
        >
        <textarea className="cmnt-txt" autoFocus style={{"width":"100%","minHeight":"150px"}} onChange={hanndleChangeText} value={commentText} ></textarea>
        </Modal>
<Modal
          title="Reply"
          visible={visibleReplyModal}
          onOk={handleReply}
          okText ="Send"
          onCancel={handleReplyCancel}
className="replyModal"
          footer={[
            <Button key="cancel" onClick={handleReplyCancel}>
              Cancel
            </Button>,
            <Button key="reply" type="primary"  onClick={()=>handleReply(0)}>
              Reply
            </Button>,
            <Button key="replyclose" type="primary"  onClick={()=>handleReply(1)}>
            Reply & Close ticket
          </Button>,
          ]}
        >
          {replyDatas&&replyDatas.length>0 &&
           <List
           itemLayout="vertical"
           dataSource={replyDatas}
           renderItem={item => (
             <List.Item>
               <List.Item.Meta
                 avatar={<Avatar>{makeReplyLogo(item.respondedBy)}</Avatar>}
                 title={<div><a>{item.replyName}</a><span style={{'float':'right','fontSize':'12px'}}>{timeFormat(item.dateforreport)}</span></div>}
           description={<div>{item.replymessage}<br/></div>}
               />
             
             </List.Item>
           )}
         />
          
        
          
        
        }
        <textarea className="cmnt-txt" autoFocus style={{"width":"100%","minHeight":"150px"}} onChange={hanndleChangeReply} value={replyText} ></textarea>
        </Modal>
</div>


    ) ;
  
}

export default LeftList