import React from 'react';
import { List, Avatar, Space , Progress } from 'antd';

import { SearchOutlined, SaveOutlined, DeleteOutlined, ShoppingOutlined, CloseSquareOutlined, MessageOutlined, LikeOutlined, StarOutlined  } from '@ant-design/icons';
import './style.scss'
import moment from 'moment'
import LiveProgress from './liveProgress';


const trimMsg = (message, length)=>{

  if (message && message.length > length) {
    return message.substring(0, length-1) + '...';
  } else return message


}

const IconText = ({ icon, text }) => (
  <span className="action-icons">
    {React.createElement(icon)}
    {text}
  </span>
);


class LeftList extends React.Component {



  render() {
const {listData} = this.props
    return(
<List
    itemLayout="horizontal"
    size="small"
    // split ="true"
    pagination={{
      onChange: page => {
        console.log(page);
      },
      pageSize: 4,
    }}
    dataSource={listData}
   
    renderItem={item => (
      <List.Item
        key={item.title}
       
      
      >
        <div class="ant-list-item-meta-avatar">
          <div className="avt-circle">
          RWH
          </div>
         
        
       </div>
       
        <List.Item.Meta
         
         title={<span>{trimMsg(item.mailsubject,30)}  <LiveProgress postedDate ={item.date} key ="pgbar" /></span>}
          description={<div> {trimMsg(item.mailmessage,100)} <div>
          <IconText icon={SaveOutlined}  key="list-vertical-star-o" />
          <IconText icon={DeleteOutlined}  key="list-vertical-like-o" />
           <IconText icon={ShoppingOutlined}  key="list-vertical-message" />
           <IconText icon={CloseSquareOutlined}  key="list-vertical-close" />
      
        </div></div>}  
          
        />
       
      
       
      {/* {item.mailmessage} */}

      </List.Item>
    )}
  />

    ) ;
  }
}

export default LeftList