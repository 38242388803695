
import {  CLIENT_FETCH_BEGINS, CLIENT_FETCH_SUCCESS, CLIENT_FETCH_FAILURE } from '../helpers/constants'


export default (state = {}, action) => {
    switch (action.type) {
        case CLIENT_FETCH_BEGINS:
            return {
                loader:true,
                result: null,
                error: null
            }
        case CLIENT_FETCH_SUCCESS:
            return {
                loader: false,
                result: action.payload,
                error: null
            }
        case CLIENT_FETCH_FAILURE:
            return {
                loader: false,
                result: null,
                error: action.payload,
            }
        default:
            return state
    }
}