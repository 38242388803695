import React from 'react';
import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, CloseSquareOutlined } from '@ant-design/icons';
import './style.scss'


const data = [
  {
    key: '1',
    name: 'Joh Brown',
   
    message: 'Please Update the version of current project',
    status:'open',
   
    id:1
  },
  {
    key: '2',
    name: 'Joe Black',
  
    message: 'Please Update the version of current project',
    status:'open',
  
    id:2
  },
  {
    key: '3',
    name: 'Jim Green',
  
    message: 'Please Update the version of current project',
    status:'open',
  
    id:3
  },
  {
    key: '4',
    name: 'Jim Red',
   
    message: 'Please Update the version of current project',
    status:'open',
  
    id:4
  },
];
  
  class FilterTableMini extends React.Component {
    state = {
      searchText: '',
      searchedColumn: '',
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
    
      render() {
        const columns = [
          {
            title: 'Client',
            dataIndex: 'clientname',
            key: 'clientname',
            width: '50%',
            ...this.getColumnSearchProps('clientname'),
          },
          {
            title: 'Message',
            dataIndex: 'mailsubject',
            key: 'mailsubject',
            width: '40%',
            ...this.getColumnSearchProps('mailsubject'),
            render: message => {
              if (message.length > 16) {
                return message.substring(0, 10) + '...';
              } else return message
    
    
    
            }
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '5%',
            ...this.getColumnSearchProps('status'),
          },
       
          {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (id) => (
              <Space size={5}>
              
                <a href="#"><CloseSquareOutlined /></a>
    
              </Space>)
          },
        ];
        return <Table columns={columns} scroll={{ x: true }} dataSource={this.props.data} size="small" bordered />;
      }
    }

    export default FilterTableMini