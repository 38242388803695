
import {  CREATE_CLIENT_BEGINS, CREATE_CLIENT_SUCCESS, CREATE_CLIENT_FAILURE, CREATE_CLIENT_CLEAR } from '../helpers/constants'


export default (state = {loader:false,
    result: "",
    error: null}, action) => {
    switch (action.type) {
        case CREATE_CLIENT_BEGINS:
            return {
                loader:true,
                result: "",
                error: null
            }
        case CREATE_CLIENT_SUCCESS:
            return {
                loader: false,
                result: action.payload,
                error: null
            }
        case CREATE_CLIENT_FAILURE:
            return {
                loader: false,
                result: "",
                error: action.payload,
            }
        case CREATE_CLIENT_CLEAR:
            return {
                loader: false,
                result: "",
                error: null,
            }
        default:
            return state
    }
}