import { combineReducers } from 'redux';
import authReducer from './authReducer';
import dashTicketReducer from './dashTicketReducer';
import changeStatusReducer from './changeStatusReducer';
import listStatusReducer from './listStatusReducer';
import usersListReducer from './usersListReducer';
import trashIdsReducer from './trashIdsReducer';
import closeTicketsReducer from './closeTicketsReducer';
import usersReducer from './usersReducer';
import createUserReducer from './createUserReducer';
import updateUserReducer from './updateUserReducer';
import clientsReducer from './clientsReducer';
import updateClientReducer from './updateClientReducer';
import createClientReducer from './createClientReducer';
import clientDetailsReducer from './clientDetailsReducer';
import clientEditModeReducer from './clientEditModeReducer';
export default combineReducers({
    authReducer,
    dashTicketReducer,
    changeStatusReducer,
    listStatusReducer,
    usersListReducer,
    trashIdsReducer,
    closeTicketsReducer,
    usersReducer,
    createUserReducer,
    updateUserReducer,
    clientsReducer,
    updateClientReducer,
    createClientReducer,
    clientDetailsReducer,
    clientEditModeReducer,

});