import React from 'react';
import { Table, Input, Button, Space , Progress } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, SaveOutlined, DeleteOutlined, ShoppingOutlined, CloseSquareOutlined } from '@ant-design/icons';
import './style.scss'
import moment from 'moment'
import LiveProgress from './liveProgress';


// const data = [
//   {
//     key: '1',
//     name: 'John Brown',
//     ticket: 32223,
//     message: 'Please Update the version of current project',
//     status: 'open',
//     sla: 25,
//     id: 1
//   },
//   {
//     key: '2',
//     name: 'Joe Black',
//     ticket: 4563,
//     message: 'Please Update the version of current project',
//     status: 'open',
//     sla: 60,
//     id: 2
//   },
//   {
//     key: '3',
//     name: 'Jim Green',
//     ticket: 56578,
//     message: 'Please Update the version of current project',
//     status: 'open',
//     sla: 10,
//     id: 3
//   },
//   {
//     key: '4',
//     name: 'Jim Red',
//     ticket: 445567,
//     message: 'Please Update the version of current project',
//     status: 'open',
//     sla: 40,
//     id: 4
//   },
// ];

class FilterTable extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
            </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
            </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };



  render() {
    const columns = [
      {
        title: 'Client',
        dataIndex: 'clientname',
        key: 'clientname',
        width: '15%',
        ...this.getColumnSearchProps('clientname'),
      },
      {
        title: 'Ticket',
        dataIndex: 'ticketno',
        key: 'ticketno',
        width: '10%',
        ...this.getColumnSearchProps('ticketno'),
      },
      {
        title: 'Message',
        dataIndex: 'mailsubject',
        key: 'mailsubject',
        width: '30%',

        ...this.getColumnSearchProps('mailsubject'),
        render: message => {
          if (message.length > 16) {
            return message.substring(0, 18) + '...';
          } else return message



        }

      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        ...this.getColumnSearchProps('status'),
      },
      {
        title: 'SLA',
        dataIndex: 'date',
        key: 'date',
        width: '25%',
        // ...this.getColumnSearchProps('ticketSLA'),
        render: (date) => {
          let curD = new Date();
          const startTime = moment(date, 'YYYY/MM/DD HH:mm');
          const endTime = moment(curD, 'YYYY/MM/DD HH:mm');
          const mniDiff = endTime.diff(startTime, 'minutes', true);

          let perCent = Math.round(( parseInt(mniDiff)/60)*100)

          // return parseInt(mniDiff)

          // return <Progress percent={perCent}  status={parseInt(mniDiff)>60?"exception":"active"} />

          return <LiveProgress postedDate ={date} />
         
           
          
          
        }
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        width: '10%',
        render: (id) => (
          <Space size={5}>
            <a href="#"><SaveOutlined /></a>
            <a href="#"><DeleteOutlined /></a>
            <a href="#"><ShoppingOutlined /></a>
            <a href="#"><CloseSquareOutlined /></a>

          </Space>)
      },
    ];
    return <Table columns={columns} scroll={{ x: true }} dataSource={this.props.data} size="small" bordered />;
  }
}

export default FilterTable