import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { List, Avatar, Space, Dropdown, Menu, Typography, Modal, Button } from 'antd';
import ReadMoreReact from 'read-more-react';
import axios from 'axios';
import { API_URL } from '../helpers/config'
import moment from 'moment'

import { DeleteOutlined, ShoppingOutlined, CloseSquareOutlined, LoadingOutlined, UserSwitchOutlined, UserOutlined } from '@ant-design/icons';
import './style.scss'
import LiveProgress from './liveProgress';
import { changeStatusAction, reAssignAction, replyTicket } from '../actions/dashBoardAction'
import ReadMoreAndLess from 'react-read-more-less';
const { Paragraph } = Typography;


function RightList(props) {


  const changeStatusError = useSelector(state => state.changeStatusReducer.error)
  const changeStatusResult = useSelector(state => state.changeStatusReducer.result)
  const changeStatusLoading = useSelector(state => state.changeStatusReducer.loader)
  const listStatus = useSelector(state => state.listStatusReducer.result)

  const dispatch = useDispatch()

  const timeFormat = (date)=>{

    let stillUtc = moment.utc(date).toDate();
    let  local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');

    return local
  }

  const trimMsg = (message, length) => {

    if (message && message.length > length) {
      return message.substring(0, length - 1) + '...';
    } else return message


  }

  const stripHTML=(txt)=>{


    let tmp = document.createElement("DIV");
       tmp.innerHTML = txt;
       return    tmp.textContent||tmp.innerText ;
    
        
       }
  const makeLogo = (name) => {

    if (name) {

      let pos1 = name.indexOf('@') + 1
      let pos2 = name.indexOf('@') + 4

      if (name.length < pos2) {
        pos2 = name.length + 1
      }
      return name.substring(pos1, pos2).toUpperCase();

    } else return 'UNK'


  }
  const makeReplyLogo = (name)=>{
    if (name) {
   
      let nvalues = name.split(" ");
      console.log("nnnnnnnn", nvalues)
      let f_name = nvalues[0][0];
      let l_name = nvalues[1] ? name.substr(name.indexOf(' ') + 1,name.indexOf(' ') + 2) : '';
     
     return f_name+l_name.toUpperCase();
    } else return 'UNK'
  }

  const IconText = ({ icon, text }) => (
    <span className="action-icons" title={text}>
      {React.createElement(icon)}

    </span>
  );

  const onClick = (id, status) => {

    dispatch(changeStatusAction({ id, status, listStatus }))

  };
  const assignToUser = (id, userid) => {

    dispatch(reAssignAction({ id, userid, listStatus }))

  };

  //reply
const [replyDatas, setReplyData] = useState([]);
const [visibleReplyModal, changeReplyVisibility] = useState(false);
const [replyTicketId, setReplyTicketId] = useState('');
const [replyText, setReplyText] = useState('');
const [highlightArray, sethighlightArray] = useState(["RWH"]);


  const toggleReplyModal =   async (id) => {
    setReplyData([])
    changeReplyVisibility(true)
    setReplyTicketId(id)
  
  await axios.post(API_URL + 'auth/listreplys',{id},{
    headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
  } )
    .then(res => {
        if (res.data.status === 'success') {
  
          setReplyData(res.data.replays)
          
        } else {
        

        }
    })
    .catch(error => {
      //
 }); };

 const handleReply = (closeStatus) => {

  let replyData = {
    replymessage:replyText,
    id:replyTicketId,
    closeStatus
  }


  dispatch(replyTicket(replyData))
  changeReplyVisibility(false)
    setTimeout(() => {
    setReplyText("")
    }, 1000);
  
  };
  const handleReplyCancel = (e) => {
    changeReplyVisibility(false)
    setReplyText("")
    
  
  };
  const hanndleChangeReply = (e) => {
    setReplyText(e.target.value)
  
  };
// reply ends

  const { listData, usersList } = props
  const [pageSize, setPageSize] = useState(5);
const onShowSizeChange=(current, pageSize)=> {
  setPageSize(pageSize)

  console.log(current, pageSize);
}
  return (
    <div>
    <List
      itemLayout="horizontal"
      size="small"
      // split ="true"
      pagination={{
        onChange: page => {
          console.log(page);
        },
        pageSize: pageSize,
        onShowSizeChange:onShowSizeChange,
        pageSizeOptions:['5','10','20','50','100'],
        hideOnSinglePage:true
      }}
      dataSource={listData}

      renderItem={item => (
        <List.Item
        className={ highlightArray.includes(makeLogo(item.clientname))?"highlighted":''}
          key={item.title} >
          <div class="ant-list-item-meta-avatar">
            <Avatar icon={<UserOutlined />} />
            <div className="logo-text">{makeLogo(item.clientname)}</div>
          </div>


          <List.Item.Meta

            title={<div className="list-title-row">
              <a href={item.ticketurl ? item.ticketurl : "#"} target="_blank" >{stripHTML(trimMsg(item.mailsubject, 10))}</a>

              <div className="title-right">
                <LiveProgress closedDate={item.closingDate} postedDate={item.date} status={item.status} key="pgbar" />
                <div style={{ "paddingLeft": "5px" }}>

                  <Dropdown overlay={(<Menu >
                    <Menu.Item key={`main`} onClick={() => assignToUser(item.id, 0)} >Main</Menu.Item>

                    {usersList && usersList.map((user) => {

                      return <Menu.Item key={`${user.name}`} onClick={() => assignToUser(item.id, user.id)} > {user.name} </Menu.Item>


                    })

                    }
                  </Menu>)} >

                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                      {<IconText icon={UserSwitchOutlined} key="list-vertical-star-o" text="re-assign" />}
                    </a>
                  </Dropdown>

                </div>
                <Dropdown overlay={(<Menu >

                  <Menu.Item key={`${item.id}0`} onClick={() => onClick(item.id, 0)} > 
                  {/* <IconText icon={DeleteOutlined} key="list-vertical-like-o" text="spam" />  */}
     <DeleteOutlined/>

                  Delete
                  </Menu.Item>

                  <Menu.Item key={`${item.id}1`} onClick={() => onClick(item.id, 2)} > 
                  {/* <IconText icon={ShoppingOutlined} key="list-vertical-message" text="Duplicate" />  */}
    <ShoppingOutlined/>

                  Spam
                  </Menu.Item>

                  <Menu.Item key={`${item.id}2`} onClick={() => onClick(item.id, 5)} >
                    {/* <IconText icon={CloseSquareOutlined} key="list-vertical-close" text="Close" /> */}
    <CloseSquareOutlined/>

                    Close
                     </Menu.Item>

                </Menu>)} >
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {changeStatusLoading != item.id ? 'More' : <LoadingOutlined style={{ "padding": "0 7px" }} />}
                  </a>
                </Dropdown>
              </div>
            </div>}
            description={<div>
              
            
            <ReadMoreReact text={stripHTML(item.mailmessage)}
            key ={item.id}
            min={50}
            ideal={100}
            max={2500}
            readMoreText="..."/>


<Space size={10}>

  <a onClick={()=>toggleReplyModal(item.id)} title="Reply" >Reply</a>

</Space>
               
               </div>}

          />



        </List.Item>
      )}
    />


    <Modal
          title="Reply"
          visible={visibleReplyModal}
          onOk={handleReply}
          okText ="Send"
          onCancel={handleReplyCancel}
className="replyModal"
          footer={[
            <Button key="cancel" onClick={handleReplyCancel}>
              Cancel
            </Button>,
            <Button key="reply" type="primary"  onClick={()=>handleReply(0)}>
              Reply
            </Button>,
            <Button key="replyclose" type="primary"  onClick={()=>handleReply(1)}>
            Reply & Close ticket
          </Button>,
          ]}
        >
          {replyDatas&&replyDatas.length>0 &&
           <List
           itemLayout="vertical"
           dataSource={replyDatas}
           renderItem={item => (
             <List.Item>
               <List.Item.Meta
                 avatar={<Avatar>{makeReplyLogo(item.respondedBy)}</Avatar>}
                 title={<div><a>{item.replyName}</a><span style={{'float':'right','fontSize':'12px'}}>{timeFormat(item.dateforreport)}</span></div>}
           description={<div>{item.replymessage}<br/></div>}
               />
             
             </List.Item>
           )}
         />
          
        
          
        
        }
        <textarea className="cmnt-txt" autoFocus style={{"width":"100%","minHeight":"150px"}} onChange={hanndleChangeReply} value={replyText} ></textarea>
        </Modal>

        </div>

  );

}

export default RightList