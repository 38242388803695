import React from 'react';
import { Table, Input, Button, Space , Progress, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ExclamationCircleOutlined, DeleteOutlined, FormOutlined, StopOutlined,EyeOutlined, IssuesCloseOutlined, LoadingOutlined } from '@ant-design/icons';
import './style.scss'
import moment from 'moment'
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';
import {updateClient, setEditData} from '../actions/clientAction'

const { confirm } = Modal;
class ClientList extends React.Component {

constructor(props){

  super(props)
this.toggleConfirmPopup = this.toggleConfirmPopup.bind(this)

 
}

toggleConfirmPopup = (row)=>{
   let formData = {
        id:row.id,
        fname:row.fname,
        lname:row.lname,
        companyname:row.companyname,
        email:row.emai,
        status:0
      }

  confirm({
    title: 'Are you sure delete this client?',
    icon: <ExclamationCircleOutlined />,
    content: 'Some descriptions',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk(row){
      console.log('OK');
  //  this.props.changeStatus(formData)
   
    },
    onCancel() {
      console.log('Cancel');
    },
  });
}
// editClient = (row)=>{

// this.props.setEditData(row)
// this.props.history.push('/edit-client')

// }

  state = {
    searchText: '',
    searchedColumn: '',
  };


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
            </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
            </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };



  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
        width: '25%',
        ...this.getColumnSearchProps('Name'),
      },
  
      {
        title: 'Email',
        dataIndex: 'Email',
        key: 'Email',
        width: '25%',
        ...this.getColumnSearchProps('Email'),
      },
      {
        title: 'Phone',
        dataIndex: 'Phone',
        key: 'Username',
        width: '10%',
        ...this.getColumnSearchProps('Phone'),
      },
      {
        title: 'Address',
        dataIndex: 'Address',
        key: 'Address',
        width: '30%',
        ...this.getColumnSearchProps('Address'),
      },
  
    
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        width: '10%',
        render: (id,row) => {
        
            return (
              <Space size={5}>
              <Link to={`/profile/${id}`} title="View"><EyeOutlined /></Link>
              <Link to={`/edit-client/${id}`} title="Edit"><FormOutlined /></Link>
              {/* <a href="#" onClick={()=>this.editClient(row)} title="Edit"><FormOutlined /></a> */}
           
              {/* <a href="#" title="Delete" onClick={()=>this.toggleConfirmPopup(row)}  ><DeleteOutlined /></a> */}
            
          
  
            </Space>
              
            )
        
         
          
        
        }
      },
    ];

    const {clientsList, changeStatusLoader} = this.props
    return <Table columns={columns} dataSource={clientsList} size="small" bordered pagination={{ hideOnSinglePage:true} ,{pageSize:10}} />;
  }
}

const mapStateToProps = state => {
  return {
  
    changeStatusLoader: state.createClientReducer.loader,
    // changeStatusError: state.updateUserReducer.error,
  }
}
const mapDispatchToProps = dispatch => ({
  changeStatus: (data) => dispatch(updateClient(data)),
  setEditData: (row) => dispatch(setEditData(row)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ClientList)