
import React from 'react'
import {Row, Col, Breadcrumb, Card, Statistic, Switch, Radio ,Dropdown, Menu,   Select, Button , Checkbox, Modal, Avatar, Typography } from 'antd';
import {
  GroupOutlined,
  WalletOutlined,
CreditCardOutlined,
CarryOutOutlined,
TeamOutlined,
EllipsisOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import {getTickets, changeListStatus, trashTicketsAction, changeTrashStatusAction} from '../actions/dashBoardAction'
import {getClientList, getClientDetails} from '../actions/clientAction'
import {Link} from 'react-router-dom'
import LeftList from './leftList';
import RightList from './rightList';
const { Title } = Typography;

const CamalCase = (text)=>{
  if(text){
let t1=""
let t2=""
    let splitTxt = text.split(" ")
if(splitTxt[0]){
  t1= splitTxt[0].charAt(0).toUpperCase() + 
  splitTxt[0].slice(1); 
}
if(splitTxt[1]){
  t2= splitTxt[1].charAt(0).toUpperCase() + 
  splitTxt[1].slice(1); 
}
return t1+" "+t2

  }else return "";

}
const makeLogo = (text)=>{
  if(text){
    let t1=""
    let t2=""
        let splitTxt = text
    if(splitTxt[0]){
      t1= splitTxt[0].toUpperCase()
    }
    if(splitTxt[1]){
      t2= splitTxt[1].toUpperCase() 
      
    }
    return t1+t2
    
      }else return "";

//   if(text){
// let t1=""
// let t2=""
//     let splitTxt = text.split(" ")
// if(splitTxt[0]){
//   t1= splitTxt[0].charAt(0).toUpperCase()
// }
// if(splitTxt[1]){
//   t2= splitTxt[1].charAt(0).toUpperCase() 
  
// }
// return t1+t2

//   }else return "";

}
class dashboard extends React.Component{
  constructor(props) {
    super(props)

    this.state = {
      list:0,
      showClientModal:false,
      clientInfo:{}
    }

   
    this.toggleTokenType = this.toggleTokenType.bind(this)
    this.toggleUser = this.toggleUser.bind(this)
    this.batchDelete = this.batchDelete.bind(this)
    this.toggleCheckBoxes = this.toggleCheckBoxes.bind(this)
    this.toggleClientInfo = this.toggleClientInfo.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    
   
  }

  toggleClientInfo=(client)=>{

    this.props.getClientDetails(client.ClientId)

    this.setState({
      showClientModal:true,
      clientInfo:client
    })



  }
  handleCancel=()=>{

    this.setState({
      showClientModal:false,
     
    })



  }

 
  toggleCheckBoxes = (e)=>{

this.props.trashStatusAction(e.target.checked)


  }
  batchDelete = (e)=>{
    const {trashIds, assignedStatus, listStatus } = this.props
let postData = {

  ids: trashIds,
  list: listStatus,
  assigned: assignedStatus
}
    this.props.trashTicketsAction(postData)

  }
  toggleTokenType = (e)=>{

const {assignedStatus} = this.props

this.props.getTickets({"list":e.target.value,'assigned':assignedStatus})

this.props.changeListStatus(e.target.value, assignedStatus)

  }
  toggleUser = (value)=>{

    console.log("value", value)
    const {listStatus} = this.props
let newValue = ["0"]
    if(value.length>0){
   
     
        this.props.getTickets({"list":listStatus,'assigned':value})
        
    
          this.props.changeListStatus(listStatus, value)

    }
    else{

     
        this.props.getTickets({"list":listStatus,'assigned':newValue})
        
    
          this.props.changeListStatus(listStatus, newValue)
    }


  }
 


  componentDidMount(){

 
   
    this.intervalId = setInterval(() => this.props.getTickets({"list":this.props.listStatus,"assigned":this.props.assignedStatus}), 300000);
    this.props.getTickets({"list":0,"assigned":["0"]})

    this.props.getClientList()

   
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
}


render(){
const {ticketsleftCount, leftTicketResult, rightTicketResult, usersList, ticketLoader, listStatus, assignedStatus, ticketsOpenCount, ticketNewCount ,trashStatus, trashIds, closeTicketsLoader, clientsList, clientsListLoader, clientDetails, profileLoader } = this.props

const {showClientModal, clientInfo} = this.state

return(

    <div className="dashv-4">
     
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 },16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        
      <div className="filterArea">
    
      <Card  bordered={false}  style={{"height":"100%", "marginTop":"16px"}} >
      <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      <Breadcrumb style={{ marginBottom: '30px' }}>
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            </Breadcrumb>


  <Radio.Group onChange={this.toggleTokenType}>
  <Radio.Button checked={listStatus==0?true:false}  value="0">New</Radio.Button>&nbsp;
  <Radio.Button checked={listStatus==3?true:false}  value="3">Answered</Radio.Button>&nbsp;
  <Radio.Button checked={listStatus==1?true:false} value="1">Closed</Radio.Button>&nbsp;
  <Radio.Button checked={listStatus==2?true:false}  value="2">All</Radio.Button>
 
        </Radio.Group>
&nbsp;

<Checkbox style={{"marginLeft":"16%"}} onChange={this.toggleCheckBoxes}>Close Tickets</Checkbox>
<Button onClick={this.batchDelete} style={{"display":trashStatus?"inline":"none"}}  className="del-token-button" type="primary" loading = {closeTicketsLoader} disabled={trashIds.length>0?false:true}>
          Batch Close
        </Button>
       



&nbsp;
{! clientsListLoader ?<Dropdown 

overlay={ <Menu>

  {clientsList && clientsList.map((client)=>{
 return <Menu.Item>
 {/* <a  rel="noopener noreferrer" onClick={e => this.toggleClientInfo(client)} >
  {client.Name && client.Name.toUpperCase()}
 </a> */}
 <Link to={`/profile/${client.id}`}  rel="noopener noreferrer" >
  {client.Name && client.Name.toUpperCase()}
 </Link>
</Menu.Item>

  }) }
</Menu>}
>
              <a style={{"marginLeft":"25px"}} className="ant-dropdown-link" onClick={e => e.preventDefault()} >

              Clients Info
            
              </a>
            </Dropdown>:''}

&nbsp;
       
        <Select 
         style={{ "minWidth": 120 ,float:"right" }} onChange={this.toggleUser}
         mode="tags"
         defaultValue={"0"}
         placeholder="Assigned to"
         defaultActiveFirstOption = {true}
         
         >
       <Select.Option  value="0">Unassigned</Select.Option>

        {usersList&& usersList.map((user)=>{

return  <Select.Option value={`${user.id}`}>{user.name}</Select.Option>

   })
          }
    
          </Select>

          </Col>
      </Row>

          </Card>

       

  </div>
 
        
      </Col>
      </Row>
  
        
    <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 },24]}>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
      
        <Card  bordered={false} loading={ticketLoader} style={{"height":"100%"}} >
       
        
       <LeftList listData = {leftTicketResult} usersList = {usersList}  />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
        <Card  bordered={false} loading={ticketLoader} style={{"height":"100%"}}>
      <RightList listData = {rightTicketResult} usersList = {usersList} />
     

        </Card>
      </Col>
     
    </Row>
  
  

  <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 },24]}>
      <Col span={6}>
        <Card  bodyStyle={{background:"#ff7875"}} loading={ticketLoader} >
        <Statistic
            title="New Tickets"
            value={ticketNewCount}
            precision={2}
            valueStyle={{ color: '#fff' }}
            prefix={<CreditCardOutlined />}
            suffix=""
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card  bodyStyle={{background:"#73d13d"}} loading={ticketLoader}>
        <Statistic
            title="Open Tickets"
            value={ticketsOpenCount}
            precision={2}
            valueStyle={{ color: '#fff' }}
            prefix={<CarryOutOutlined />}
            suffix=""
          />
       
        </Card>
      </Col>
      <Col span={6}>
        <Card  bordered={false}  bodyStyle={{background:"#5cdbd3"}} loading={ticketLoader}>
        <Statistic
            title="Closed Tickets out SLA"
            value={0}
            precision={2}
            valueStyle={{ color: '#fff' }}
            prefix={<WalletOutlined />}
            suffix=""
          />
       
        </Card>
      </Col>
      <Col span={6}>
        <Card  bordered={false}  bodyStyle={{background:"#b37feb"}} loading={ticketLoader}>
        <Statistic
            title="Client"
            value={0}
            precision={2}
            valueStyle={{ color: '#fff' }}
            prefix={<TeamOutlined />}
            suffix=""
          />
        </Card>
      </Col>
     
     
    </Row>

    {/* <Modal
    width = {750}
          title={false}
          visible={showClientModal}
          footer={null}
          maskClosable={true}
          onCancel={this.handleCancel}
        >
         <Row >
      <Col xs={24} sm={24} md={24} lg={4} xl={4}>
     { <Avatar shape="circle" size={80} style={{"fontSize":"35px"}} >{makeLogo(clientInfo.Name)}</Avatar>}
        </Col>
      
  <Col xs={24} sm={24} md={24} lg={20} xl={20}>
      <Title >{CamalCase(clientInfo.Name+" "+'')}</Title>
      {!profileLoader && clientDetails? <table style={{"width":"100%","alignContent":"baseline"}}>
        <tr><td colSpan={2} ><b>Contact Info</b></td></tr>
        <tr>
          <td>Name</td>
          <td>: {CamalCase(clientDetails.client.Name)}</td>
         </tr>
          <tr>
          <td>Address</td>
          <td dangerouslySetInnerHTML={{__html:": "+clientDetails.client.Address}}></td>
          </tr><tr>
          <td>Email</td>
          <td>: {clientDetails.client.Email}</td>
          </tr><tr>
          <td>Phone</td>
          <td>: {clientDetails.client.Phone}</td>
          </tr>
          <tr><td colSpan={2}><hr/></td></tr>

        <tr><td colSpan={2}><b>Help desk details</b></td></tr>
          <tr>
          <td>Url</td>
          <td>: {clientDetails.clientdatacenterInfo&&clientDetails.clientdatacenterInfo.helpdeskurl}</td>
          </tr>
          <tr>
          <td>UserName</td>
          <td>: {clientDetails.clientdatacenterInfo && clientDetails.clientdatacenterInfo.Username}</td>
          </tr><tr>
          <td>Password</td>
          <td>: {clientDetails.clientdatacenterInfo && clientDetails.clientdatacenterInfo.Password}</td>
        </tr>
        <tr><td colSpan={2}><hr/></td></tr>


        <tr><td colSpan={2}><b>Current OS, Kernal, and Software Specs</b></td></tr> 
          <tr>
          <td>OS</td>
          <td>: {clientDetails.clientOsInfo && clientDetails.clientOsInfo.operatingsystem}</td>
          </tr>
          <tr>
          <td>Specs</td>
          <td>: {clientDetails.clientOsInfo && clientDetails.clientOsInfo.Specs}</td>
          </tr>
        <tr><td colSpan={2}><hr/></td></tr>

        <tr><td colSpan={2}><b>Server Details</b></td></tr> 
          <tr>
          <td>Server Name</td>
          <td>: {clientDetails.clientserverInfo && clientDetails.clientserverInfo.ServerName}</td>
          </tr>
          <tr>
          <td>IP</td>
          <td>: {clientDetails.clientserverInfo && clientDetails.clientserverInfo.IPAddress}</td>
          </tr>
          <tr>
          <td>Port</td>
          <td>: {clientDetails.clientserverInfo && clientDetails.clientserverInfo.port}</td>
          </tr>
          <tr>
          <td>SSH</td>
          <td>: {clientDetails.clientserverInfo && clientDetails.clientserverInfo.sshnew}</td>
          </tr>
          <tr>
          <td>Password</td>
          <td>: {clientDetails.clientserverInfo && clientDetails.clientserverInfo.upassword}</td>
          </tr>
          <tr>
          <td>Root Password</td>
          <td>: {clientDetails.clientserverInfo && clientDetails.clientserverInfo.RootPassword}</td>
          </tr>
       

       
      </table>:<div>Loading...</div>}
        </Col>
        </Row>
        </Modal> */}


        
    </div>


  
)





}


}
const mapStateToProps = state => {
  return {


    leftTicketResult: state.dashTicketReducer.ticketsleft,
    rightTicketResult: state.dashTicketReducer.ticketsright,
    ticketsleftCount: state.dashTicketReducer.ticketsleftCount,
    ticketsOpenCount: state.dashTicketReducer.ticketsOpenCount,
    ticketNewCount: state.dashTicketReducer.ticketNewCount,
    // usersList: state.dashTicketReducer.usersList,
    usersList: state.usersListReducer.usersList,

    ticketError: state.dashTicketReducer.error,
    ticketLoader: state.dashTicketReducer.loader,

    listStatus: state.listStatusReducer.list,
    assignedStatus: state.listStatusReducer.assigned,

    trashIds: state.trashIdsReducer.ids,
    trashStatus: state.trashIdsReducer.status,

    closeTicketsLoader: state.closeTicketsReducer.loader,


    clientsList: state.clientsReducer.result,
    clientsListLoader: state.clientsReducer.loader,
    clientsListError: state.clientsReducer.error,

    clientDetails: state.clientDetailsReducer.result,
    profileLoader: state.clientDetailsReducer.loader,
    clientDetailsError: state.clientDetailsReducer.error,

  }
}
const mapDispatchToProps = dispatch => ({
  getTickets: (data) => dispatch(getTickets(data)),
  changeListStatus: (list, assigned) => dispatch(changeListStatus(list, assigned)),
  trashTicketsAction: (postData) => dispatch(trashTicketsAction(postData)),
  trashStatusAction: (status) => dispatch(changeTrashStatusAction(status)),
  getClientList: () => dispatch(getClientList()),
  getClientDetails: (id) => dispatch(getClientDetails(id)),


})
export default connect(mapStateToProps, mapDispatchToProps)(dashboard)