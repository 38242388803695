import { TICKET_FETCH_BEGINS, TICKET_FETCH_SUCCESS, TICKET_FETCH_FAILURE,
    STATUS_CHANGE_BEGINS, STATUS_CHANGE_SUCCESS, STATUS_CHANGE_FAILURE , CHANGE_LIST_STATUS, CHANGE_TRASH_IDS, CLOSE_TICKETS_BEGINS, CLOSE_TICKETS_SUCCESS, CLOSE_TICKETS_FAILURE, CHANGE_TRASH_STATUS, 
    COMMENT_TICKET_BEGINS, COMMENT_TICKET_SUCCESS, COMMENT_TICKET_FAILURE,
    REPLY_TICKET_BEGINS, REPLY_TICKET_SUCCESS, REPLY_TICKET_FAILURE
 } from '../helpers/constants'
import { API_URL } from '../helpers/config'
import axios from 'axios';
import { history } from '../helpers/history'
import {  message } from 'antd';



export  const  getTickets = (formData) =>  dispatch => {
    dispatch({ type: TICKET_FETCH_BEGINS });
   

       axios.post(API_URL + 'auth/gettickets',formData,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
    .then(res => {
            if (res.data.status === 'success') {
    
                 dispatch({
                    type: TICKET_FETCH_SUCCESS,
                    payload: res.data
                })
              

            } else {
            
                   dispatch({
                    type: TICKET_FETCH_FAILURE,
                    payload: res.data
                })
                 message.info(`Some error occurred. Please try after some time`);
            }
        })
        .catch(error => {  dispatch({
            type: TICKET_FETCH_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}
export const changeStatusAction = (postData) => dispatch => {
    dispatch({ type: STATUS_CHANGE_BEGINS, payload:postData.id });


    axios.post(API_URL + 'auth/updateStatus',postData,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
        .then(res => {
            if (res.data.status === 'success') {
    
                dispatch({
                    type: STATUS_CHANGE_SUCCESS,
                    payload:  postData.id,
                   
                })
                message.info(`${res.data.message}`);


if(postData.status ==1){
       setTimeout(() => {
                dispatch(getTickets({"list":postData.listStatus, "assigned":postData.assignedStatus}))
                    
                });
}
                
         

            } else {
            
                dispatch({
                    type: STATUS_CHANGE_FAILURE,
                    payload: res.data.message
                })
                message.info(`${res.data.message}`);
            }
        })
        .catch(error => {dispatch({
            type: STATUS_CHANGE_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}
export const commentTicket = (postData) => dispatch => {
    dispatch({ type: COMMENT_TICKET_BEGINS, payload:postData.id });
   

    axios.post(API_URL + 'auth/addRemarks',postData,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
        .then(res => {
            if (res.data.status === 'success') {
    
                dispatch({
                    type: COMMENT_TICKET_SUCCESS,
                    payload: res.data.message,
                   
                })
                message.info(`${res.data.message}`);
              

            } else {
            
                dispatch({
                    type: COMMENT_TICKET_FAILURE,
                    payload: res.data.message
                })
                message.info(`${res.data.message}`);
            }
        })
        .catch(error => {dispatch({
            type: COMMENT_TICKET_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}
export const replyTicket = (postData) => dispatch => {
    dispatch({ type: REPLY_TICKET_BEGINS, payload:postData.id });


   

    axios.post(API_URL + 'auth/replyTicket',postData,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
        .then(res => {
            if (res.data.status === 'success') {
    
                dispatch({
                    type: REPLY_TICKET_SUCCESS,
                    payload: res.data.message,
                   
                })

                if(postData.closeStatus==1){
                    dispatch({
                        type: STATUS_CHANGE_SUCCESS,
                        payload:  postData.id,
                       
                    })
                }
                message.info(`${res.data.message}`);
              

            } else {
            
                dispatch({
                    type: REPLY_TICKET_FAILURE,
                    payload: res.data.message
                })
                message.info(`${res.data.message}`);
            }
        })
        .catch(error => {dispatch({
            type: REPLY_TICKET_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}
export  const fetchReplies = async (id)=> {

    // let samples = [
    //     'This ticket is invalid',
    //     'this is a demo ticket',
    //     'this cant be closed',
    //     'we are working on this ticket'
    // ]

   

        
   
  
   
 await axios.post(API_URL + 'auth/listreplys',{id},{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
        .then(res => {
            if (res.data.status === 'success') {
    
               return "1"
              
            } else {
            
             
                  message.info(`${res.data.message}`);
            }
        })
        .catch(error => {
            
        
             message.info(`Some error occurred. Please try after some time`);
              history.push("/login")
        }
        
        );
}
export const trashTicketsAction = (postData) => dispatch => {
    dispatch({ type: CLOSE_TICKETS_BEGINS });
   

    axios.post(API_URL + 'auth/closeTickets',postData,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
        .then(res => {
            if (res.data.status === 'success') {
    
                dispatch({
                    type: CLOSE_TICKETS_SUCCESS,
                    payload: res.data.message,
                   
                })
                message.info(`${res.data.message}`);
                dispatch(changeTrashIdsAction([]))
                setTimeout(() => {
                    dispatch(getTickets({"list":postData.listStatus, "assigned":postData.assignedStatus}))
                    
                });

            } else {
            
                dispatch({
                    type: CLOSE_TICKETS_FAILURE,
                    payload: res.data.message
                })
                message.info(`${res.data.message}`);
            }
        })
        .catch(error => {dispatch({
            type: CLOSE_TICKETS_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}
export const reAssignAction = (postData) => dispatch => {
    dispatch({ type: STATUS_CHANGE_BEGINS, payload:postData.id });
   

    axios.post(API_URL + 'auth/reasign',postData,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    } )
        .then(res => {
            if (res.data.status === 'success') {
    
                dispatch({
                    type: STATUS_CHANGE_SUCCESS,
                    payload: res.data.message,
                   
                })
                message.info(`${res.data.message}`);
                setTimeout(() => {
                    dispatch(getTickets({"list":postData.listStatus, "assigned":postData.assignedStatus}))
                    
                });

            } else {
            
                dispatch({
                    type: STATUS_CHANGE_FAILURE,
                    payload: res.data.message
                })
                message.info(`${res.data.message}`);
            }
        })
        .catch(error => {dispatch({
            type: STATUS_CHANGE_FAILURE,
            payload: 'API failed'
        })
        message.info(`Some error occurred. Please try after some time`);
        history.push("/login")
        }
        
        );
}


export const changeListStatus = (list, assigned) => dispatch => {
    let pld = {
        list,
        assigned
    }
    dispatch({ type: CHANGE_LIST_STATUS, payload:pld });
   
}
export const changeTrashIdsAction = (ids) => dispatch => {
  
    dispatch({ type: CHANGE_TRASH_IDS, payload:ids });
   
}
export const changeTrashStatusAction = (status) => dispatch => {
  
    dispatch({ type: CHANGE_TRASH_STATUS, payload:status });
   
}